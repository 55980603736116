import React, { Component } from 'react';
import ReactDOM from 'react-dom';
/* import $ from "jquery";

import StationInformations from './stationsinfo';
import Functions from './functions';
const e = React.createElement;

const ALAND = 0
const ALLER = 1
const BIESE = 2
const BODEILSE = 3
const DUMME= 4
const ELBESTROM =5
const HAVEL = 6 
const JEETZE = 7
const MILDE = 8
const MULDE = 9
const NUTHEEHLE = 10 
const OHRE = 11
const SAALE = 12
const SCHWELSTER = 13
const  SEEGE = 14
const TANGER = 15
const UCHTE = 16
const UNSTRUT = 17
const WELSTER = 18
const WIPPER= 19 */
 

const WEBAREAS = [
	'Aland, Jeetze mit Dumme', 'Aller', 'Bode/Ilse', 'Elbestrom', 'Havel', 'Mulde',
	'Nuthe/Ehle', 'Ohre', 'Saale', 'Schwarze Elster', 'Unstrut', 'Weiße Elster', 'Wipper'
]; 

class ListeAllePegel extends React.Component {
	componentDidMount(){
		this.renderContent(this.props.list, WEBAREAS);
	}

	renderContent=(list, webareas)=>{

	var Aland = [];
	var Aller = [];
	 var Biese  = [];
	 var Bode_Ilse  = [];
	 var Dumme   = [];
	 var Elbestrom  = [];
	 var Havel  = [];
	 var Jeetze = [];
	 var Milde  = [];
	 var Mulde = [];
	 var Nuthe_Ehle  = [];
	 var Ohre = [];
	 var Saale  = [];
	 var Schwarze_Elster = []; 
	 var Seege  = [];
	 var Tanger = [];
	 var Uchte  = [];
	 var Unstrut = [];
	 var Weiße_Elster  = [];
	 var Wipper = [];
	 var NoWebarea = [];


	 if(webareas.length > 1){

		list.map(item=> {											
			console.log(item.webarea);
			console.log(item);
			var tempWebarea = String(item.webarea);//.replace('/', '_').replace(' ', '_');
			console.log(tempWebarea)
			
				switch(tempWebarea){
					case 'Aland, Jeetze mit Dumme':{
						console.log("tempWebarea", tempWebarea)
						Aland.push(item);
						break;} 
					case 'Aller':{
						Aller.push(item);
						break;}
					case 'Biese':{
						Biese.push(item);
						break;} 
					case 'Bode/Ilse':{
						Bode_Ilse.push(item);
						break;}
					case 'Dumme':{
						Dumme.push(item);
						break;} 
					case 'Elbestrom':{
						Elbestrom.push(item);
						break;}
					case 'Havel':{
						Havel.push(item);
						break;} 
					case 'Jeetze':{
						Jeetze.push(item);
						break;}
					case 'Milde':{
						Milde.push(item);
						break;} 
					case 'Mulde':{
						Mulde.push(item);
						break;}
					case 'Nuthe/Ehle':{
						Nuthe_Ehle.push(item);
						break;} 
					case 'Ohre':{
						Ohre.push(item);
						break;}
					case 'Saale':{
						Saale.push(item);
						break;} 
					case 'Schwarze Elster':{
						Schwarze_Elster.push(item);
						break;} 
					case 'Seege':{
						Seege.push(item);
						break;} 
					case 'Tanger':{
						Tanger.push(item);
						break;}
					case 'Uchte':{
						Uchte.push(item);
						break;} 
					case 'Unstrut':{
						Unstrut.push(item);
						break;}
					case 'Weiße Elster':{
						Weiße_Elster.push(item);
						break;} 
					case 'Wipper':{
						Wipper.push(item);
						break;} 
					default:{
						NoWebarea.push(item);
					}
				}
		})
	 
	 
		ReactDOM.render(
			<div>		
				{
					webareas.map((webarea,key)=>{
		
			
				return	<div id={'pan1_' + webarea} class="  mb-3"	>
						<div id={'pan2_' + webarea} class="" >
							<div 
								class="text-center  menue-btn border-bottom" 
								 								
								onClick={(e)=>this.setSearchStationList(list, webarea)}
								> {webarea}								
							</div>
						</div>
						
					</div>
				})
			}
		</div>, document.getElementById('listall')
		)
	}else{
		this.setSearchStationList(list, webareas[0])
	}
		
	}

	setSearchStationList = (list, webarea)=>{
		var stationlist = list.filter(station =>{
			
			//console.log(station.webarea)
			//console.log(webarea.replace('_','/'))
			if(station.webarea == webarea){
				return station
			}
			 
		})

		
		this.props.app.loadUIHWMP(stationlist, webarea, true)
	}

	searchStation=(stations, searchstr)=> {
		return stations.map(station => {
			return [station.staname.toUpperCase().startsWith(searchstr.toUpperCase()), station.webarea]       
		})    
	  }
	

	changeInput=()=>{
		var staname = document.getElementById('filterall').value;
		var newWebareaList = [];
		var newStationList = [];

		this.props.list.map(station =>{
			if(station.staname.toUpperCase().startsWith(staname.toUpperCase())){
				newStationList.push(station);
				if(!newWebareaList.includes(station.webarea)){
					newWebareaList.push(station.webarea);
				}
			}

		});
		
		this.renderContent(newStationList, newWebareaList);
	}
	


    render() { 
	
	return (
		<div class="text-white text-center">
		<div class="">
			{/* <div>
				<div id="" class="h5">Alle Pegel</div>
			</div> */}
			<div class=" navbar-brand header-container" > 
				
				
				<div class="my-auto " onClick={					
					(e)=>this.props.app.loadStartside()
				}>
					<img class="" src="./icons/arrow_left.png" style={{width:'35px'}}/>
				</div>

				<div class="header-text">Alle Pegel</div>
					
				
				<div class="my-auto" onClick={(e)=>{this.props.app.showModalMenue()}}>
					<img src="./icons/menue_points.png" class="" style={{width:40}}/>	 
					</div>
			</div>

			<div  class="search-content">				
				
				<img class="p-2 " src="./icons/search-dark.png" style={{height:'45px'}}/>
				<input 
					id="filterall" 
									 
					name="search" 
					type="text" 
					placeholder="Pegelname"
					onChange={(e)=>this.changeInput()} 
					/>
			</div>
			
		</div>	

		<div id="listall" class="" style={{backgroundColor:'rgba(255,255,255,0.5)'}}></div>
	</div>	
    );
  }
}

export default ListeAllePegel;