import React, { Component } from 'react';


class Allgemein extends React.Component {
	componentDidMount() {
       
    }
    render() {   
		return (
			<div class="as-info-view">
				<div class=" w-100 border-bottom"> 
					
						<div class="navbar-brand">Algemeine Bedeutung</div>
					
				</div>
			
			<div className="" style={{margin:'20px auto', maxWidth:250}}>

					<div class="d-flex pt-2 pl-2">
						{/* <div class="my-auto" style={{backgroundColor: '#28A745', height: 20, width:20}}></div> */}
						<div  class="aicon" style={{backgroundColor: '#5f5', borderRadius:0, border: '2px solid #262626'}}  src={'./icons/check_icon_small.png'}/>
						<div class="text-left my-auto pl-2">Keine HW-Situation</div>	
						</div>	

					<div class="d-flex pt-2 pl-2">
						{/* <div class="my-auto" style={{backgroundColor: 'white', height: 20, width:20}}></div> */}
						<img  class="aicon" style={{backgroundColor: '#fff', borderRadius:0, border: '2px solid #262626'}}  src={'./icons/normal_icon_small.png'}/>
						<div class="text-left my-auto pl-2">Pegel ohne Richtwasserstände</div>	
						</div>
			
					<div class="d-flex pt-2 pl-2">
						{/* <div class="my-auto" style={{backgroundColor: '#888', height: 20, width:20}}></div> */}
						<img  class="aicon" style={{backgroundColor: '#ccc', borderRadius:0, border: '2px solid #262626'}}  src={'./icons/fail_icon_small.png'}/>
					<div class="text-left my-auto pl-2">Keine/veraltete Werte</div>			  
				</div>	
			</div>
			  		
				
			</div>		  	
		);
  }
}

export default Allgemein;
