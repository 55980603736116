import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";


const e = React.createElement;

var span = '<span style="font-size: 16px; font-weight: normal;">';
var	span_klein = '<span style="font-size: 12px; font-weight: normal;">';


function loadKontakt(){
		$('<div/>').attr('class', 'font-weight-bold').html('Bereitschaftsdienst der HVZ*').appendTo('#contactcontent');
        $('<div/>').attr('class','px-1 ').html('Telefon').appendTo('#contactcontent');
        $('<div/>').attr('class','px-3').html('+49 391 581-1421 ... 1424' ).appendTo('#contactcontent');
        $('<div/>').attr('class','px-3').html('+49 172 3289982').appendTo('#contactcontent');
        $('<div/>').attr('class','px-1').html('Auskunft zur hydrologischen Lage').appendTo('#contactcontent');
        $('<div/>').attr('class','px-3').html('+49 391 581-1634 ').appendTo('#contactcontent');
        $('<div/>').attr('class','px-1').html('Telefax').appendTo('#contactcontent');
        $('<div/>').attr('class','px-3 ').html('+49 391 581-1618').appendTo('#contactcontent');
        $('<div/>').attr('class','px-1').html('E-Mail').appendTo('#contactcontent');
				$('<div/>').attr('class','px-3 mb-3 ').html('hvz@lhw.mlu.sachsen-anhalt.de').appendTo('#contactcontent');
				
				
				
				$('<div/>').html(span_klein  + '*kostenpflichtig entsprechend der jeweils gültigen Orts- oder Ferngesprächstarife').appendTo('#contactcontent');        
		
}



var kontaktside = 	e('div',{className:'p-4 text-dark text-center'},
					e('div',{className:'navbar-brand mb-4'}, 
						e('h4',{},'Kontakt')					
					),				
				e('div',{className:' d-flex', onClick:function(){}},
					 e('div',{id:'contactcontent', className:'p-2 flex-grow-1 bg-white', style:{borderRadius: 10}})
				)		
	);
	
			



class KontaktSide extends React.Component {
		
	componentDidMount(){
		loadKontakt()
	}
	
    render() { 	
		return (
			<div class="py-2 text-white text-center">
				<div class="  navbar-brand header-container">
				
				
				<div class="my-auto " onClick={					
					(e)=>this.props.app.loadStartside()
				}>
					<img class="" src="./icons/arrow_left.png" style={{width:'35px'}}/>
				</div>

				
				<div class="header-text">Kontakt</div>
					
				
				<div class="my-auto" onClick={(e)=>{this.props.app.showModalMenue()}}>
					<img src="./icons/menue_points.png" class="" style={{width:40}}/>	 
					</div>
			</div>
				{/* <div>
					<div id="" class="h5">Kontakt</div>
				</div> */}

				<div class="shadow-box">

					<div class="font-weight-bold">Bereitschaftsdienst der HVZ*</div>
					<div class="px-1 pt-3 font-weight-bold">Telefon</div>
					<div class="px-3">+49 391 581-1421 ... 1424</div>
					<div class="px-3">+49 172 3289982</div>
					<div class="px-1 pt-3 font-weight-bold">Auskunft zur hydrologischen Lage</div>
					<div class="px-3">+49 391 581-1634</div>
					<div class="px-1 pt-3 font-weight-bold">Telefax</div>
					<div class="px-3">+49 391 581-1618</div>
					<div class="px-1 pt-3 font-weight-bold">E-Mail</div>
					<div class="px-3">hvz@lhw.mlu.sachsen-anhalt.de</div>
					
					</div>
				
				<div class="py-3 text-dark">*kostenpflichtig entsprechend der jeweils gültigen Orts- oder Ferngesprächstarife</div>
			
			
			</div>
       		
		);
  }
}

export default KontaktSide;



