import React, { Component } from 'react';
//import ReactDOM from 'react-dom';

//import PDFContentView from './PDFContentView';
//import StaNavView from './Stanavview';
import { Document, Page, pdfjs } from "react-pdf";

import moment from 'moment';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pdfdoc: null,
      numPages: null, 
      pageNumber: 1 ,
      w:0,
      h:0,
      menue: null
    }
  }

  componentDidMount(){    
    
      

    this.setState({
     
      document: this.props.document,
      w:document.getElementById('content_').offsetWidth ,
      h:document.getElementById('content_').offsetHeight
    })
      
    
  }

  componentDidUpdate(){
    console.log("update")
    
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
   
  };
  
  onDocumentLoadError = () => {
    
    
  };

  render() { 
    var pageArray =[];

    for(var i = 1; i<=this.state.numPages; i++){      
      pageArray.push(i);
    }

    
    
    
  // console.log(this.props.docs)

    return (
      <div class="pdf-view-content"> 
      <div class="text-center" style={{color:'#202121'}}>{this.props.date}</div>
      <div class=" navbar-brand header-container" >
					
					
					<div class="my-auto " onClick={					
						this.props.back
					}>
						<img class="" src="./icons/arrow_left.png" style={{width:'35px'}}/>	
					</div>

					
					<div class="header-text">{this.props.title}</div>
						
					
					<div class="my-auto" onClick={(e)=>{this.props.app.showModalMenue()}}>
					  <img src="./icons/menue_points.png" class="" style={{width:40}}/>	 
					</div>
				</div>	
        
        
    <div class="pdf-view">
     

              <div id="pdfDocument" class="pdf-document">
                <Document
               
                  file={this.state.document}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                  onLoadError={this.onDocumentLoadError}
                  >
                    {pageArray.map(page => (<Page pageNumber={page}  width={this.state.w } height={this.state.h}   />))}
                  </Document>

                  </div>
                  </div>
                  </div>
            
        
    );
  }

}

export default App;