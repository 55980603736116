import React, { Component } from 'react';
import ReactDom from 'react-dom';

class App extends React.Component {		
	getCalledAlerts=()=>{
		(async () => {      
			const response = await fetch('./data/documents/misc/alarmmeldungen.json');
			var json = await response.json();      
			
			if(json != null){
				ReactDom.render(<div class="my-2 text-left">
								<div class="px-2">Ausgerufene Alarmstufen</div>
								<div class="px-2">in den einzelnen Landkreisen</div>
								{
									json.map((alert)=>(
										<div class="my-2 text-left p-2 mx-4 " style={{backgroundColor:'rgba(255,255,0, 0.3)', borderRadius: 5}}>
											<div class="">Gewässer: {alert.river}</div>
											<div class="">Pegel: {alert.station}</div>
											<div class="">Geltungsbereich: {alert.alert_label}</div>
											<div class="">Alarmstufe: {alert.alert_name}</div>
										</div>
									))
								}
							</div>, document.getElementById('calledAlerts')	
							)
		}
		})();
		 
		
	  }


	componentDidMount() {

		//this.getCalledAlerts();
	}
	
    render() {  
		var bgcolor_mg ='#252525';
		var color_mg ='black';

		var bgcolor_as1 ='#252525';
		var color_as1 ='white';

		var bgcolor_as2 ='#252525';
		var color_as2 ='white';

		var bgcolor_as3 ='#252525';
		var color_as3 ='white';

		var bgcolor_as4 ='#252525';
		var color_as4 ='white';
		

		if(  this.props.app.state.listMg.length > 0){
			bgcolor_mg = '#00f';
			color_mg ='white';
		}			
			
		if(  this.props.app.state.listAs1.length > 0){
			bgcolor_as1 ='#ff0';
			color_as1 ='#252525';				
		}
			
		if(  this.props.app.state.listAs2.length > 0){
			bgcolor_as2 ='#f80';
			color_as2 ='white';			
		}
			
		if(  this.props.app.state.listAs3.length > 0){
			bgcolor_as3 ='#f00';
			color_as3 ='white';			
		}
			
		if(  this.props.app.state.listAs4.length > 0){
			bgcolor_as4 ='#f0f';
			color_as4 ='white';			
		}	
			
		return (
			/* getNavStart() */
			<div class="overview">				
				
                <div 
                    id="mg_nav"
                    class="overview-btn shadow-box"                    
                    style={{backgroundColor: '#8cf'}}
                    
                    onClick={()=>{	
                            if(this.props.app.state.listMg.length > 0){
								this.props.app.loadUIHWMP(this.props.app.state.listMg, 'Meldegrenze');
								this.props.app.onHide();
                            }
                        }}>
                    
                    {/* <div id="txt_mg_nav" class="">MG</div> */}
                    <div id="cnt_mg_nav" class="mx-auto">{this.props.app.state.listMg.length}</div>
                </div>

			<div 
				id="as1_nav"
				class="overview-btn shadow-box"                    
					style={{backgroundColor: '#ff0'}}
					onClick={()=>{										
						if(this.props.app.state.listAs1.length > 0){
							this.props.app.loadUIHWMP(this.props.app.state.listAs1, 'Alarmstufe 1');
							this.props.app.onHide();
						}
					}}>
				
				{/* <div id="txt_as1_nav" class="">AS1</div> */}
				<div id="cnt_as1_nav" class="" >{ this.props.app.state.listAs1.length}</div>
			</div>

			<div 
				id="as2_nav"
				class="overview-btn shadow-box"                    
					style={{backgroundColor: '#f80'}}
				onClick={()=>{										
						if(this.props.app.state.listAs2.length > 0){
							this.props.app.loadUIHWMP(this.props.app.state.listAs2, 'Alarmstufe 2');
							this.props.app.onHide();
						}
					}}>
				
				{/* <div id="txt_as2_nav" class="">AS2</div> */}
				<div id="cnt_as2_nav" class="mx-auto">{ this.props.app.state.listAs2.length}</div>
			</div>

			<div 
				id="as3_nav"
				class="overview-btn shadow-box"                    
					style={{backgroundColor: '#f00'}}
				onClick={()=>{										
						if(this.props.app.state.listAs3.length > 0){
							this.props.app.loadUIHWMP(this.props.app.state.listAs3, 'Alarmstufe 3');
							this.props.app.onHide();
						}
					}}>
				
				{/* <div id="txt_as3_nav" class="" >AS3</div> */}
				<div id="cnt_as3_nav" class="mx-auto">{ this.props.app.state.listAs3.length}</div>
			</div>

			<div 
				id="as4_nav"
				class="overview-btn shadow-box"                    
					style={{backgroundColor: '#f0f'}}
				onClick={()=>{										
						if(this.props.app.state.listAs4.length > 0){
							this.props.app.loadUIHWMP(this.props.app.state.listAs4, 'Alarmstufe 4');
							this.props.app.onHide();
						}
					}}>
				
				{/* <div id="txt_as4_nav" class="">AS4</div> */}
				<div id="cnt_as4_nav" class="mx-auto">{ this.props.app.state.listAs4.length}</div>
			</div>
				
			<div id="calledAlerts"></div>
									
		</div>
	)
  	}
}

export default App;



