import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MapView from './mapview'
import Alarm from './alarm';
import Allgemein from './allgemein';
import HwInfo from './hwi';
import HwiSide from './hwiside';
import HwwSide from './hwwside';
import HwWarning from './hww';

const e = React.createElement;

class Startseite extends React.Component {
	componentDidMount() {	
		
		/* if(this.props.app.state.hwi.length != 0){
			ReactDOM.render(
			   <HwInfo app={this.props.app}/>, document.getElementById('hwinfo')
			);
		}
		if(this.props.app.state.hww.length != 0){
			ReactDOM.render(
			   <HwWarning app={this.props.app}/>, document.getElementById('hwwarning')
			);
		} */

		//this.props.app.loadOverview();

		ReactDOM.render(
		   <MapView app={this.props.app}/>, document.getElementById('mapview'),()=>{
			if(this.props.app.state.hwi.length > 0 || this.props.app.state.hww.length > 0){
				setTimeout(()=>{this.props.app.showModal()},1000);
			}
		   }
		);	
    }
	
	
    render() {   
		return (
			<div class="text-white">
									
				{/* <div id="hwinfo"></div>
				<div id="hwwarning"></div> */}
				
				<div id="mapview" class="map-view"></div>
				{/* <div id="alarm"></div>
				<div id="allgemein"></div> */}
				<div id="pegel"></div>
				<div id="main"></div>
						
			</div>
		);
	}
}

export default Startseite;