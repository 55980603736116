import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Intro from './intro'
import HwInfo from './hwi';
import HwWarning from './hww';
import Overview from './overview.js'
import Alarm from './alarm'
import Allgemein from './allgemein'
import './App.css';
import './bootstrap.min.css';

import ReactDOM from 'react-dom';
import Main from './main'

import StationInformations from './stationsinfo';

import Startseite from './startseite';
import HWMP from './hwmp';
import ALLP from './allp';
import PROGELBE from './prog_elbe';
import Shortcuts from './shortcuts';
import Kontakt from './kontakt';
import Impressum from './impressum';
import Infos from './infos';
import HwiSide from './hwiside';
import HwwSide from './hwwside';


import stationInfos from './data/stationsInfo.json'
import moment from 'moment';

import './style.css';
import { add } from 'ol/coordinate';

const timestampFormat = 'DD.MM.YYYY HH:mm';

class StartApp extends Component {
	constructor(props) {
		super(props);
		
         
        this.state = {
			version: '0.6.0',
			update:"2023.01.09_0700",
			createdAt: "",
			showModal: false,
			showMenueModal:false,
		   	fileHWI: './data/documents/Hochwasserinformationen/index.json',
			fileHWI_intern: './mobileweb/data/documents/Hochwasserinformationen/index.json',
			fileHWW: './data/documents/Hochwasserwarnungen/index.json',
			fileStations: './data/stationsInfo.json',	
			hwi: [],
			hww: [],				
			stations: [],
			listAllStations: [],
			listNormal: [],
			listNoData: [],
			listOldData: [],
			listHwmpNormal: [],
			listMg: [],
			listAs1: [],
			listAs2: [],
			listAs3: [],
			listAs4: [],
			listHwmp: [],
			listNoHwmp: [],
		}
	}
		
	

	/* formateDate=(d)=>{
		//return d.toISOString();
		let year = d.getFullYear();
		let month = (d.getMonth()+1);
		let day = d.getDate();
		let hour = d.getHours();
		let min = d.getMinutes();
		
		if (month < 10){month = "0" + month;}    
		if (day < 10){day = "0" + day;}    
		if (hour < 10){hour = "0" + hour;}    
		if (min < 10){min = "0" + min;}      
		return day + "." + month + "." + year + " " + hour + ":" + min;
	} */

	sortListByStationNumer = (list)=>{
		if(list.length != 0){	
			list.sort(function(a, b){
				let x = a.stano.toLowerCase();
				let y = b.stano.toLowerCase();
				if (x < y) {return -1;}
				if (x > y) {return 1;}
				return 0;
			});
		}
		return list;
	}

	fill=(values)=>{

		let result = [];
		let i

		for(i=0; i<values.length; i++){	

			if(i < (values.length - 1)){
				let dateNow = Number(values[i].ts);
				let dateNext = Number(values[i+1].ts);
				result.push({
					x:new Date(dateNow), 
					y:values[i].value
				});

				dateNow = moment(dateNow).add(15, "minutes")
				while((dateNext - dateNow)/60/1000 > 15){							
					dateNow = moment(dateNow).add(15, "minutes").valueOf();													
					result.push({x:new Date(dateNow), y:null});
				}
			}	

			
			if(i == (values.length - 1)){
				
				result.push({
					x:new Date(Number(values[values.length - 1].ts)), 
					y:values[i].value
				});
			}
		} 

		return result 
	}

	loadData(){	

		fetch( this.state.fileHWI)
  			.then(response => response.json())
			  .then(data => {
				this.cb_gethwi(data);
			  })
			  .catch(function(err) {
				console.log(err);
			});

			fetch( this.state.fileHWW)
  			.then(response => response.json())
			  .then(data => {
				this.cb_gethww(data);
			  })
			  .catch(function(err) {
				console.log(err);
			});

			fetch(this.state.fileStations)
  			.then(response => response.json())
			  .then(data => {
				this.cb_getfile(data);	
			  })
			  .catch(function(err) {
				console.log(err);
			});		
	}

	cb_gethwi=(json)=>{
		let thisClass = this;
		////console.log(json);
		thisClass.setState({
			hwi: []
		},()=>{
			json.map(item=>{		
				thisClass.state.hwi.push(item.filename);		
			});
		})		
	}
	
	cb_gethww=(json)=>{
		
		this.setState({
			hww: []
		},()=>{
			json.map(item=>{		
				this.state.hww.push(item.filename);		
			});
		})		
	} 

	cb_getfile=(json)=>{
		let thisClass = this;
		//console.log(json);
		
		let lhwmp = [];
		let lnohwmp = [];	
		
		let lnormal = [];	
		let lnodata = [];
		let lolddata = [];
		
		let lhwmpnormal = [];
		let lmg = [];
		let las1 = [];
		let las2 = [];
		let las3 = [];
		let las4 = [];
		
		let stations=[]
		
		let createdAt = moment(Number( json.createdAt)).format(timestampFormat);//thisClass.formateDate(new Date(Number(json.createdAt)));
		//console.log('createdAt: ' + createdAt);
		//console.log(json.stations);
		json.stations.map((item, key)=> {			
			
			stations.push({
				stano: String(item.number), 
				staname: String(item.name),
				sitename: String(item.owner), 
				waterobj: String(item.waterobj), 
				webarea: String(item.webfilter),
				geo: [item.easting, item.northing],
				//prog:String(item.vorhersagedauer),
				//est:String(item.abschaetzungsdauer),
				value:  null,
				values: [],
				//values_prog: null,
				//values_prog_min: null,
				//values_prog_max: null,
				//values_est: null,
				//values_est_min: null,
				//values_est_max: null,
				unit: item.w_unit,
				ts: null,
				//tss: null,
				//tss_prog:null,
				qvalue: null,
				qvalues: [],
				qunit: item.q_unit,
				qts: null,
				//qtss: null,
				state: {
					old: true,
					hwmp: null,
					as: null,
					classification: '#ccc',
					img: 'fail'
				},
				qstate:{
					old: null
				},
				w_as:  item.w_as,
				
							
			});
			
			let station = stations[key];
			
			
			
			
			if(item.w_values != null && item.w_values.length != 0){
				
				station.value = item.w_values[item.w_values.length -1].value;
				//console.log(item.w_values[item.w_values.length -1].ts)
				let lTS = item.w_values[item.w_values.length -1].ts
				station.ts = moment(Number(lTS)).format(timestampFormat);//thisClass.formateDate(new Date(Number(lTS)));
			
				//station.ts = formateDate(new Date(item.w_values[item.w_values.length -1].ts));
				
				station.values = this.fill(item.w_values);
				//console.log(station);
				
				
				
				//////prüfe Daten, ob veraltete
				let heute = new Date();   //Startzeitpunkt
				let millisekunden_heute = heute.getTime();

				
				
				
				
										
				if(millisekunden_heute - lTS < 86400000){
					stations[key].state.old = false;
				}else{
					stations[key].state.old = true;
				}
			///////////////////////////////////////////////
			//console.log('set as');		
			//////Alarmstatus setzen
			
			
				if(station.w_as != null){
					if(station.w_as.mg != null || station.w_as.as1 != null || station.w_as.as2 != null || station.w_as.as3 != null || station.w_as.as4 != null){
						
							//////console.log(station.staname + station.value);
							station.state.hwmp = true;
							
							if(!station.state.old){
								
								station.state.as = 'ok';
								station.state.classification = '#8f8';
								station.state.img =  'check';
								station.state.edge =  4;

								
								if(station.w_as.mg != null  && station.w_as.mg.value != null){						
									if(station.value >= station.w_as.mg.value){	
										////////console.log(station.staname + station.w_as.mg.value);								
										station.state.as = 'mg';
										station.state.classification = '#8cf';
										station.state.img =  'mg';
										station.state.edge =  20;
										
                  
									}
								}
										
								if(station.w_as.as1 != null  && station.w_as.as1.value != null){							
									if	(station.value >=station.w_as.as1.value){
										//////console.log(station.staname + station.w_as.as1.value);
										station.state.as = 'as1';	
										station.state.classification = '#ff0';
										station.state.img =  'aone';	
										station.state.edge =  20;
										
                  						
									}							
								}
									
								if(station.w_as.as2 != null && station.w_as.as2.value != null){							
									if(station.value >= station.w_as.as2.value){
										//////console.log(station.staname + station.w_as.as2.value);
										station.state.as = 'as2';
										station.state.classification = '#f80';
										station.state.img =  'atwo';
										station.state.edge =  20;
										
                  
									}
								}
									
								if(station.w_as.as3 != null  && station.w_as.as3.value != null){							
									if(station.value >=station.w_as.as3.value){
										//////console.log(station.staname + station.w_as.as3.value);
										station.state.as = 'as3';
										station.state.classification = '#f00';
										station.state.img =  'athree';
										station.state.edge =  20;
										
                  
									}
								}
									
									
								if(station.w_as.as4 != null  && station.w_as.as4.value != null){						
									if(station.value >=station.w_as.as4.value){	
										//////console.log(station.staname + station.w_as.as4.value);
										station.state.as = 'as4';								
										station.state.classification = '#f0f';
										station.state.img =  'afour';
										station.state.edge =  20;
										
									}
								}
							}else{
							/////////////////todo
								station.state.as = 'old';
								station.state.classification = '#ccc';
								station.state.img =  'fail';
								station.state.edge =  4;
							}
							
						lhwmp.push(station);//in sammelliste hwmp
						
					}else{
						//kein Hochwassermeldepegel
						station.state.hwmp = false;
						station.state.classification = '#fff';
						station.state.img =  'normal';
						station.state.edge =  4;
						lnohwmp.push(station);	
						
					}
				}	
			
	//console.log('fill lists');				
				//Füllen der Listen						
				if(!station.state.old){
					
					if(station.state.hwmp){
						
						switch(station.state.as){
							case 'ok':{
								lhwmpnormal.push(station);
								break;
							}
							case 'mg':{
								lmg.push(station);
								break;
							}
							case 'as1':{
								las1.push(station);
								break;
							}
							case 'as2':{
								las2.push(station);
								break;
							}
							case 'as3':{
								las3.push(station);
								break;
							}
							case 'as4':{
								las4.push(station);
								break;
							}
						}
						
					}else{
						lnormal.push(station);
					}						
					
				}else{
					lolddata.push(station);
				}					
				
			}else{
				station.state.as = 'old';
				station.state.classification = '#ccc';
				station.state.img =  'fail';
				station.state.edge =  4;
				
				lnodata.push(station);
				
				if(station.w_as != null){
					if(station.w_as.mg != null || station.w_as.as1 != null || station.w_as.as2 != null || station.w_as.as3 != null || station.w_as.as4 != null){
						lhwmp.push(station);
					}else{
						lnohwmp.push(station);	
					}
				}
			}
			
			
			if(item.q_values != null && item.q_values.length != 0){
				station.qvalue = String(item.q_values[item.q_values.length -1].value).replace(".", ",");
				station.qts =  moment(Number(item.q_values[item.q_values.length -1].ts)).format('DD.MM.YYYY HH:mm');
				
				station.qvalues = this.fill(item.q_values);
				/* let i
				for(i=0; i<item.q_values.length; i++){							
					station.qvalues.push({
						x: new Date(Number(item.q_values[i].ts)), 
						y:item.q_values[i].value
					});	
				} */
			}
			/* 
			$('#dynamic').attr('aria-valuemax', json.stations.length);
			$('#stationcnt').text(key + ' von ' +  json.stations.length + ' Station(en) geladen');
		 */
		
			//console.log(stations);	
		});
	

		thisClass.setState({
			//console.log(stations);
			createdAt:createdAt,
			listAllStations: thisClass.sortListByStationNumer(stations),					
			listNormal: thisClass.sortListByStationNumer(lnormal),
			listNoData: thisClass.sortListByStationNumer(lnodata),
			listOldData: thisClass.sortListByStationNumer(lolddata),

			listHwmpNormal: thisClass.sortListByStationNumer(stations),//lhwmpnormal);

			listMg: thisClass.sortListByStationNumer(lmg),
			listAs1: thisClass.sortListByStationNumer(las1),
			listAs2: thisClass.sortListByStationNumer(las2),
			listAs3: thisClass.sortListByStationNumer(las3),
			listAs4: thisClass.sortListByStationNumer(las4),
			listHwmp: thisClass.sortListByStationNumer(lhwmp),
			listNoHwmp: thisClass.sortListByStationNumer(stations),//sortListByStationNumer(lnohwmp);

			ltzAkt: '(ltz. Akt. ' + moment(new Date()).format(timestampFormat)

		},()=>{
			
			ReactDOM.render(
				<Main app={thisClass}/>, document.getElementById('app'),()=>{
					
				}
			);
		})			
	}


	loadIntro(){
		ReactDOM.render(
			<Intro/>, document.getElementById('app')
		);	
	}

	loadOverview(){
		if(this.state.listMg.length != 0 || this.state.listAs1.length != 0 || this.state.listAs2.length != 0 || this.state.listAs3.length != 0 || this.state.listAs4.length != 0)
		{
			ReactDOM.render(
				<Overview app={this}/>, document.getElementById('overview')
			);
		}
	} 


	loadHwiSide(catchments, filejson){
		
		ReactDOM.render(
		  <HwiSide app={this} catchments={catchments} filejson={filejson}/>, document.getElementById('content_')
		);	
	}

	loadHwwSide(catchments, filejson){
	
		ReactDOM.render(
		  <HwwSide app={this} catchments={catchments} filejson={filejson}/>, document.getElementById('content_')
		);	
	}

	loadStartside(){
	
		ReactDOM.render(
		  <Startseite app={this}/>, document.getElementById('content_')
		);
	}

	loadUIStationInformation=(list, stano, listname, backbtn)=>{
		/* this.loadNavStation(list, index, listname); */

		let index = 0;
		list.filter((station, key)=>{			
			if(station.stano == stano){
					index = key
			}
		});
		ReactDOM.unmountComponentAtNode(document.getElementById('content_'));
		ReactDOM.render(
		  <StationInformations app={this}	list={list} index={index} listname={listname} backbtn={backbtn}/>, document.getElementById('content_')
		);
	}

	loadUIHWMP=(list, listname, backbtn)=>{
		
	
		ReactDOM.unmountComponentAtNode(document.getElementById('content_'));
		ReactDOM.render(
			<HWMP app={this} list={list} listname={listname} backbtn={backbtn}/>, document.getElementById('content_')
		);				
	}

	loadUIAllStations(){
		
		ReactDOM.render(
				<ALLP app={this} list={this.state.listAllStations} listname='Alle Pegel' />, document.getElementById('content_')
		);
	}	

	loadPrognose(){
		
		ReactDOM.render(
			<PROGELBE app={this}/>, document.getElementById('content_')
		);
		
	}
	loadShortcutside(){
		
		ReactDOM.render(
		  <Shortcuts app={this}/>, document.getElementById('content_')
		);	
	}

	loadKontaktside(){
		
		ReactDOM.render(
		  <Kontakt app={this} />, document.getElementById('content_')
		);	
	}

	loadImpressumside(){
	
		ReactDOM.render(
		  <Impressum app={this} />, document.getElementById('content_')
		);	
	}

	
	loadInfoside(){		
		ReactDOM.render(
		  <Infos app={this} version={this.state.version} createdAt={this.state.createdAt} />, document.getElementById('content_')
		);	
	}


	componentDidMount() {
		      setTimeout(() => {
				this.loadData();
			  }, 3000);
		  
			
	}


	showModalMenue=()=>{		
		this.setState({
			showMenueModal:true
		})
		}

		onHideMenue=()=>{
			this.setState({
				showMenueModal:false
			})
		}


	showModal=()=>{
		
		this.setState({
			showModal:true
		},()=>{
			if(this.state.hwi.length > 0){
				ReactDOM.render(
				   <HwInfo app={this}/>, document.getElementById('hwinfo')
				);
			}
			if(this.state.hww.length > 0){
				ReactDOM.render(
				   <HwWarning app={this}/>, document.getElementById('hwwarning')
				);
			}
			this.loadOverview();
		})
	}
	
	onHide=()=>{
		this.setState({
			showModal:false
		})
	}
  render() {
    return (
				
			<div id="app" class="app" >				
				<Intro/>
				<Modal
					show={this.state.showMenueModal}
					onHide={()=>{this.onHideMenue()}}
					
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered

				
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter" style={{color:'#202121'}} >
						Menü
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
					<div class="modal-body">
						<div id="" class=" mt-2 " >	


 <div 
 class="menue-btn"  
 data-dismiss="modal" 
 aria-label="Close"
 onClick={()=>{
    this.loadIntro();
	this.loadData();
	this.onHideMenue();
 }}>
 <div class="d-block">
     <div class="">Aktualisieren</div>
     <div id="actts" class="small align-text-bottom pl-1">{this.state.ltzAkt}</div>		
 </div>
</div>
 
<div 
 class="menue-btn" 
 
 data-dismiss="modal" 
 aria-label="Close"
 onClick={()=>{
	this.loadStartside();
	 this.onHideMenue();
	}
}>Startseite</div>


<div 
 class="menue-btn" 
 
 data-dismiss="modal" 
 aria-label="Close"
 onClick={
     (e)=>{
        	
		this.loadUIHWMP(this.state.listHwmp, 'Alle HW-Meldepegel', false);	
		 this.onHideMenue();
     }
}>Alle HW-Meldepegel</div>
         
<div 
 class="menue-btn" 
 
 data-dismiss="modal" 
 aria-label="Close"
 onClick={()=>{
    
		this.loadPrognose();
		 this.onHideMenue();
 }
}>Elbe-Vorhersage</div>
                     
<div 
 class="menue-btn" 
 
 data-dismiss="modal" 
 aria-label="Close"
 onClick={()=>{
	this.loadUIAllStations();
	 this.onHideMenue();
 }
}>Alle Pegel</div>
                     
<div 
 class="menue-btn" 
 
 data-dismiss="modal" 
 aria-label="Close"
 onClick={()=>{
     	
	this.loadShortcutside();			
	 this.onHideMenue();							
 }
}>Abkürzungen</div>
                     
<div 
 class="menue-btn" 
 
 data-dismiss="modal" 
 aria-label="Close"
 onClick={()=>{
    
	 window.open("https://www.sachsen-anhalt.de/meta/datenschutz/");
	 this.onHideMenue();
 }
}>Datenschutz</div>
                     
<div 
 class="menue-btn" 
 
 data-dismiss="modal" 
 aria-label="Close"
 onClick={()=>{
     
	this.loadKontaktside();
	 this.onHideMenue();
 }
}>Kontakt</div>
                     
<div 
 class="menue-btn" 
 
 data-dismiss="modal" 
 aria-label="Close"
 onClick={()=>{
     
	this.loadImpressumside();
	 this.onHideMenue();		
 }
}>Impressum</div>
                     
<div 
 class="menue-btn" 
 
 data-dismiss="modal" 
 aria-label="Close"
 onClick={()=>{
    
	this.loadInfoside();
	 this.onHideMenue();
 }
}>Informationen</div>												
</div>	
</div>
					</Modal.Body>
					
					</Modal>


				<Modal
				show={this.state.showModal}
				onHide={()=>{this.onHide()}}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter" >
						Informationen
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div id="hwinfo"></div>
						<div id="hwwarning"></div>
						{/* <div id="overview" class="" style={{position:"relative", top:10}}></div> */}
						<div class="modal-body">
						<Alarm/> 
						<Allgemein/> 
					</div>
					</Modal.Body>
					{/* <Modal.Footer>
						<div onClick={this.onHide}>Close</div>
					</Modal.Footer> */}
					</Modal>						
			</div>
					
    );
  }
}

export default StartApp;
