import React, { Component } from 'react';
import Functions from './functions';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';

class Header extends React.Component {
	
    componentDidMount() {
        console.log("loadHeader ready");
	}
	
	render() { 
		var thisClass = this;
		return (	
			<div class="bg-light text-dark rounded shadow-box" style={{ 
				position:'relative',
				top:20}}>

			<nav class="navbar " >
					<div 
						class="d-flex ml-4" 
						onClick={(e)=>{ 
								$('#navbarsExampleDefault').collapse('hide');	
								thisClass.props.app.loadStartside();
								}
						}>					
							
							<div class="">
								<img class="img-fluid" src="./icons/logoatlhw_dark.png" style={{width:200, height:25}}/>
							</div>
					</div>

					<div 
						class="navbar-toggler text-dark text-center" 
						style={{width:45, height:45}}						
						data-toggle="collapse" 
						data-target="#navbarsExampleDefault" 
						aria-controls="navbarsExampleDefault" 
						aria-expanded="false" 
						aria-label="Toggle navigation"
					>
						<img src="./icons/points_dark.png" class="img-fluit" style={{width:25}}/>						
					</div>

					<div id="navbarsExampleDefault" class="collapse navbar-collapse text-dark mt-2 " >
						
						<div 
							class="mx-4 mb-1 p-2 " 
							style={{borderRadius: 10}}
							onClick={()=>{
								this.props.app.loadIntro();
								this.props.app.loadData();
							}}>
							<div class="d-block">
								<div class="">Aktualisieren</div>
								<div id="actts" class="small align-text-bottom pl-1">{this.props.ltzakt}</div>		
							</div>
						</div>
							
						<div 
							class="mx-4 mb-1 p-2 " 
							style={{borderRadius: 10}}
							onClick={()=>{
								$('#navbarsExampleDefault').collapse('hide');
								thisClass.props.app.loadStartside();}
						}>Startseite</div>


						<div 
							class="mx-4 mb-1 p-2 " 
							style={{borderRadius: 10}}
							onClick={
								(e)=>{
									$('#navbarsExampleDefault').collapse('hide');	
									thisClass.props.app.loadUIHWMP(this.props.app.state.listHwmp, 'Alle HW-Meldepegel', false);	
								}
						}>Alle HW-Meldepegel</div>
									
						<div 
							class="mx-4 mb-1 p-2 " 
							style={{borderRadius: 10}}
							onClick={()=>{
								$('#navbarsExampleDefault').collapse('hide');
									this.props.app.loadPrognose();
							}
						}>Elbe-Vorhersage</div>
												
						<div 
							class="mx-4 mb-1 p-2 " 
							style={{borderRadius: 10}}
							onClick={()=>{
								$('#navbarsExampleDefault').collapse('hide');	
								thisClass.props.app.loadUIAllStations();
							}
						}>Alle Pegel</div>
												
						<div 
							class="mx-4 mb-1 p-2 " 
							style={{borderRadius: 10}}
							onClick={()=>{
								$('#navbarsExampleDefault').collapse('hide');	
								this.props.app.loadShortcutside();										
							}
						}>Abkürzungen</div>
												
						<div 
							class="mx-4 mb-1 p-2 " 
							style={{borderRadius: 10}}
							onClick={()=>{
								$('#navbarsExampleDefault').collapse('hide');
								window.open("https://www.sachsen-anhalt.de/meta/datenschutz/");
							}
						}>Datenschutz</div>
												
						<div 
							class="mx-4 mb-1 p-2 " 
							style={{borderRadius: 10}}
							onClick={()=>{
								$('#navbarsExampleDefault').collapse('hide');
								this.props.app.loadKontaktside();
							}
						}>Kontakt</div>
												
						<div 
							class="mx-4 mb-1 p-2 " 
							style={{borderRadius: 10}}
							onClick={()=>{
								$('#navbarsExampleDefault').collapse('hide');
								this.props.app.loadImpressumside();		
							}
						}>Impressum</div>
												
						<div 
							class="mx-4 mb-1 p-2 " 
							style={{borderRadius: 10}}
							onClick={()=>{
								$('#navbarsExampleDefault').collapse('hide');
								this.props.app.loadInfoside();
							}
						}>Informationen</div>												
					</div>		
			</nav>		
			</div>	

		);
	}
}
export default Header;