import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";

import Intro from './intro'
import Main from './main'
import Header from './header';
import Shortcuts from './shortcuts';
import Infos from './infos';
import Kontakt from './kontakt';
import Impressum from './impressum';
import NavStart from './navstart';
import NavStation from './navstation'
import Startseite from './startseite';
import HwInfo from './hwi';
import HwiSide from './hwiside';
import HwwSide from './hwwside';
import HwWarning from './hww';
import HWMP from './hwmp';
import PROGELBE from './prog_elbe';
import ALLP from './allp';
import StationInformations from './stationsinfo';


const e = React.createElement;


const version = "0.1.5";
var createdAt = "";

const fileHWI = './data/documents/Hochwasserinformationen/index.json'; 	//'../../../../data/documents/Hochwasserinformationen/index.json';
const fileHWI_intern = './mobileweb/data/documents/Hochwasserinformationen/index.json';
const fileHWW = './data/documents/Hochwasserwarnungen/index.json';		//'../../../../data/documents/Hochwasserwarnungen/index.json';
const fileSTATIONS = './data/stationsInfo.json';					//'../../../../data/mdata/stationsInfo.json'; 
/* const fileSTATIONS = './data/mdata/stationsInfo.json';					//'shvzwww1/intranet/data/mdata/stationsInfo.json'; */

var hwi = [];
var hww = [];
	
var stations = [];
var listAllStations = [];


var listNormal = [];
var listNoData = [];
var listOldData = [];

var listHwmpNormal = [];
var listMg = [];
var listAs1 = [];
var listAs2 = [];
var listAs3 = [];
var listAs4 = [];

var listHwmp = [];
var listNoHwmp = [];



var confMainTitle = 'bg-secondary text-white text-center';// border-bottom';
var confSecTitle = 'navbar-brand px-2 py-1 my-3 bg-secondary text-white';

var span = '<span style="font-size: 16px; font-weight: bold;">';
var	span_klein = '<span style="font-size: 12px; font-weight: normal;">';
var varBack ='';
var ltzAkt = '';
var tempStationListPos = 0;	

var test = function(){alert('test');}





//////////////////////////////////////////////////////////	
function formateDate(d){
		//return d.toISOString();
		var year = d.getFullYear();
		var month = (d.getMonth()+1);
		var day = d.getDate();
		var hour = d.getHours();
		var min = d.getMinutes();
		
		if (month < 10){month = "0" + month;}    
		if (day < 10){day = "0" + day;}    
		if (hour < 10){hour = "0" + hour;}    
		if (min < 10){min = "0" + min;}      
		return day + "." + month + "." + year + " " + hour + ":" + min;
	}
//////////////////////////////////////////////////////////
function sortListByStationNumer(list){
		if(list.length != 0){	
			list.sort(function(a, b){
				var x = a.stano.toLowerCase();
				var y = b.stano.toLowerCase();
				if (x < y) {return -1;}
				if (x > y) {return 1;}
				return 0;
			});
		}
		return list;
	}
 
 //////////////////////////////////////////////////////////

 function cb_gethwi(json){
	
	console.log(json);
	hwi = [];
	
	$.each(json, function(key, item ){		
		hwi.push(item.filename);		
	});
}

function cb_gethww(json){
	//console.log(json);
	hww = [];
	console.log(json)
	$.each(json, function(key, item ){		
		hww.push(item.filename);
	});	
} 
	
	
	
function cb_getfile(json){
		//console.log(json);
		
		var lhwmp = [];
		var lnohwmp = [];	
		
		var lnormal = [];	
		var lnodata = [];
		var lolddata = [];
		
		var lhwmpnormal = [];
		var lmg = [];
		var las1 = [];
		var las2 = [];
		var las3 = [];
		var las4 = [];
		
		
		createdAt = formateDate(new Date(Number(json.createdAt)));
		console.log('createdAt: ' + createdAt);
		//console.log(json.stations);
		$.each( json.stations, function( key, item ) {			
			
			stations.push({
				stano: String(item.number), 
				staname: String(item.name),
				sitename: String(item.owner), 
				waterobj: String(item.waterobj), 
				webarea: String(item.webfilter),
				geo: [item.easting, item.northing],
				//prog:String(item.vorhersagedauer),
				//est:String(item.abschaetzungsdauer),
				value:  null,
				values: [],
				//values_prog: null,
				//values_prog_min: null,
				//values_prog_max: null,
				//values_est: null,
				//values_est_min: null,
				//values_est_max: null,
				unit: item.w_unit,
				ts: null,
				//tss: null,
				//tss_prog:null,
				qvalue: null,
				qvalues: [],
				qunit: item.q_unit,
				qts: null,
				//qtss: null,
				state: {
					old: null,
					hwmp: null,
					as: null
				},
				qstate:{
					old: null
				},
				w_as:  item.w_as			
			});
			
			var station = stations[key];
			
			
			
			
			if(item.w_values != null && item.w_values.length != 0){
				
				station.value = item.w_values[item.w_values.length -1].value;
				//console.log(item.w_values[item.w_values.length -1].ts)
				var lTS = item.w_values[item.w_values.length -1].ts
				station.ts = formateDate(new Date(Number(lTS)));
				//console.log(station.ts);
				//station.ts = formateDate(new Date(item.w_values[item.w_values.length -1].ts));
				
				
				var i
				for(i=0; i<item.w_values.length; i++){							
					station.values.push({x:new Date(Number(item.w_values[i].ts)), y:item.w_values[i].value});	
				}
				
				
				//////prüfe Daten, ob veraltete
				var heute = new Date();   //Startzeitpunkt
				var millisekunden_heute = heute.getTime();

				
				
				
				
										
				if(millisekunden_heute - lTS < 86400000){
					stations[key].state.old = false;
				}else{
					stations[key].state.old = true;
				}
			///////////////////////////////////////////////
			//console.log('set as');		
			//////Alarmstatus setzen
			
			
				if(station.w_as != null){
					if(station.w_as.mg != null || station.w_as.as1 != null || station.w_as.as2 != null || station.w_as.as3 != null || station.w_as.as4 != null){
						
							//////console.log(station.staname + station.value);
							station.state.hwmp = true;
										
							if(!station.state.old){
								
								station.state.as = 'ok';
								
								if(station.w_as.mg != null  && station.w_as.mg.value != null){						
									if(station.value >= station.w_as.mg.value){	
										////////console.log(station.staname + station.w_as.mg.value);								
										station.state.as = 'mg';
									}
								}
										
								if(station.w_as.as1 != null  && station.w_as.as1.value != null){							
									if	(station.value >=station.w_as.as1.value){
										//////console.log(station.staname + station.w_as.as1.value);
										station.state.as = 'as1';								
									}							
								}
									
								if(station.w_as.as2 != null && station.w_as.as2.value != null){							
									if(station.value >= station.w_as.as2.value){
										//////console.log(station.staname + station.w_as.as2.value);
										station.state.as = 'as2';
									}
								}
									
								if(station.w_as.as3 != null  && station.w_as.as3.value != null){							
									if(station.value >=station.w_as.as3.value){
										//////console.log(station.staname + station.w_as.as3.value);
										station.state.as = 'as3';
									}
								}
									
									
								if(station.w_as.as4 != null  && station.w_as.as4.value != null){						
									if(station.value >=station.w_as.as4.value){	
										//////console.log(station.staname + station.w_as.as4.value);								
										station.state.as = 'as4';
									}
								}
							}else{
							/////////////////todo
							}
							
						lhwmp.push(station);//in sammelliste hwmp
						
					}else{
						//kein Hochwassermeldepegel
						station.state.hwmp = false;
						lnohwmp.push(station);	
						
					}
				}	
			
	//console.log('fill lists');				
				//Füllen der Listen						
				if(!station.state.old){
					
					if(station.state.hwmp){
						
						switch(station.state.as){
							case 'ok':{
								lhwmpnormal.push(station);
								break;
							}
							case 'mg':{
								lmg.push(station);
								break;
							}
							case 'as1':{
								las1.push(station);
								break;
							}
							case 'as2':{
								las2.push(station);
								break;
							}
							case 'as3':{
								las3.push(station);
								break;
							}
							case 'as4':{
								las4.push(station);
								break;
							}
						}
						
					}else{
						lnormal.push(station);
					}						
					
				}else{
					lolddata.push(station);
				}					
				
			}else{
				lnodata.push(station);
				if(station.w_as != null){
					if(station.w_as.mg != null || station.w_as.as1 != null || station.w_as.as2 != null || station.w_as.as3 != null || station.w_as.as4 != null){
						lhwmp.push(station);
					}else{
						lnohwmp.push(station);	
					}
				}
			}
			
			
			if(item.q_values != null && item.q_values.length != 0){
				station.qvalue = String(item.q_values[item.q_values.length -1].value).replace(".", ",");
				station.qts = formateDate(new Date(Number(item.q_values[item.q_values.length -1].ts)));
				
				
				
				var i
				for(i=0; i<item.q_values.length; i++){							
					station.qvalues.push({
						x: new Date(Number(item.q_values[i].ts)), 
						y:item.q_values[i].value
					});	
				}
			}
			
			$('#dynamic').attr('aria-valuemax', json.stations.length);
			$('#stationcnt').text(key + ' von ' +  json.stations.length + ' Station(en) geladen');
		
		
			//console.log(stations);	
		});
	
		//console.log(stations);
		listAllStations = sortListByStationNumer(stations);
		
		listNormal = sortListByStationNumer(lnormal);			
		//console.log(listNormal);
		
		
		listNoData = sortListByStationNumer(lnodata);
		listOldData = sortListByStationNumer(lolddata);
		
		listHwmpNormal = sortListByStationNumer(stations);//lhwmpnormal);
		
		listMg = sortListByStationNumer(lmg);
		listAs1 = sortListByStationNumer(las1);
		listAs2 = sortListByStationNumer(las2);
		listAs3 = sortListByStationNumer(las3);
		listAs4 = sortListByStationNumer(las4);
		
		listHwmp = sortListByStationNumer(lhwmp);


		listNoHwmp = sortListByStationNumer(stations);//sortListByStationNumer(lnohwmp);
		
		ltzAkt = '(ltz. Akt. ' + formateDate(new Date()) + ')';
		
		console.log('showStartseite');
		loadMain();
		
		
		
		
	}
	
function loadMain(){
	ReactDOM.render(
		<Main/>, document.getElementById('root_')
	);
}	
















	
	//////////////////////////////////////////////////////////		
	
		
	

//////////////////////////////////////////////////////////	

function getStatPng(station){
	if(station.value != null){//kein Wert
		if(!station.state.old){//wenn Wert älter 24h
			if(station.state.hwmp){	//wenn kein HWMP
				if(station.state.as == 'ok' ){				
						return'./icons/kh.png';	
					}					
				if(station.state.as == 'mg' ){				
					return './icons/mg.png';
				}
				
				if(station.state.as == 'as1' ){				
					return './icons/as1.png';
				}
				
				if(station.state.as == 'as2' ){				
					return './icons/as2.png';
				}
				
				if(station.state.as == 'as3' ){				
					return './icons/as3.png';
				}
				
				if(station.state.as == 'as4' ){				
					return './icons/as4.png';
				}
			}else{
				return './icons/or.png';
			}
		}else{
			return './icons/old.png';
		}
	}else{
			return './icons/old.png';
	}
}
/////////////////////////////////////////////////////



/////////////////////////////////////////////////////////////////




class Functions extends React.Component {
	

	

	resetLocalMebers(){
	
		hwi = [];
		hww = [];
		
		stations = [];
		listAllStations = [];


		listNormal = [];
		listNoData = [];
		listOldData = [];

		listHwmpNormal = [];
		listMg = [];
		listAs1 = [];
		listAs2 = [];
		listAs3 = [];
		listAs4 = [];

		listHwmp = [];
		listNoHwmp = [];

		

		span = '<span style="font-size: 16px; font-weight: bold;">';
		span_klein = '<span style="font-size: 12px; font-weight: normal;">';
	
		tempStationListPos = 0
	}

	
	//////////////////////////////////////////////////////////
	loadData(){	
	
		//$.get('../../../../data/documents/Hochwasserinformationen/index.json').then(function(response) {
		$.get(fileHWI).then(function(response) {			
			cb_gethwi(response);
		}).catch(function(err) {
			console.log('json error');
		});
		
		//$.get('../../../../data/documents/Hochwasserwarnungen/index.json').then(function(response) {
		$.get(fileHWW).then(function(response) {
			cb_gethww(response);
		}).catch(function(err) {
			console.log('json error');
		});

		//$.get('../../../../data/mdata/stationsInfo.json').then(function(response) {	
		$.get(fileSTATIONS).then(function(response) {			
			cb_getfile(response);			
		}).catch(function(err) {
			console.log('json error');
		});
		
		
	}
	//////////////////////////////////////////////////////////
	
	
	//////////////////////////////////////////////////////////	
	getListNormal(){
		return listNormal;
	}
	getListAllStations(){
		return listAllStations;
	}
	
	getListHWMP(){
		return listHwmp;
	}
	
	getListNoHWMP(){
		return listNoHwmp;
	}
	getListNoData(){
		return listNoData;
	}
	
	
	getListHwmpNormal(){
		return listHwmpNormal;
	}
	getListMG(){
		return listMg;
	}
	getListAS1(){
		return listAs1;
	}
	getListAS2(){
		return listAs2;
	}
	getListAS3(){
		return listAs3;
	}
	getListAS4(){
		return listAs4;
	}	
	getLastActDate(){
		return ltzAkt;
	}
	
	getIcon(station){
		return getStatPng(station);
	}
	

	loadHeader(){
		ReactDOM.render(
			<Header ltzakt= {ltzAkt}/>, document.getElementById('header_')
	  );
	}
	
	loadNavStart(){			
		ReactDOM.render(
			<NavStart />, document.getElementById('topnav_')
		);
	}

	loadNavStation(list, index, listname){	
		ReactDOM.render(
			<NavStation list={list} index = {index} listname={listname}/>, document.getElementById('topnav_')
		);
	}
	
	loadStartside(){
		this.loadNavStart();
		ReactDOM.render(
		  <Startseite />, document.getElementById('content_')
		);
	}

	

	loadHwi(){	
		 if(hwi.length != 0){
			ReactDOM.render(
			   <HwInfo listDocuments={hwi}/>, document.getElementById('hwinfo')
			);
		} 
	}

	loadHww(){
		if(hww.length != 0){
			ReactDOM.render(
			   <HwWarning listDocuments={hww}/>, document.getElementById('hwwarning')
			);
		}
	}
	
	loadShortcutside(){
		this.loadNavStart();
		ReactDOM.render(
		  <Shortcuts />, document.getElementById('content_')
		);	
	}
	
	loadInfoside(){
		this.loadNavStart();
		ReactDOM.render(
		  <Infos version={version} createdAt={createdAt}/>, document.getElementById('content_')
		);	
	}

	loadKontaktside(){
		this.loadNavStart();
		ReactDOM.render(
		  <Kontakt />, document.getElementById('content_')
		);	
	}

	loadImpressumside(){
		this.loadNavStart();
		ReactDOM.render(
		  <Impressum />, document.getElementById('content_')
		);	
	}

	loadHwiSide(catchments, filejson){
		this.loadNavStart();
		ReactDOM.render(
		  <HwiSide catchments={catchments} filejson={filejson}/>, document.getElementById('content_')
		);	
	}

	loadHwwSide(catchments, filejson){
		this.loadNavStart();
		ReactDOM.render(
		  <HwwSide catchments={catchments} filejson={filejson}/>, document.getElementById('content_')
		);	
	}

	
	getConfMainTitle(){
		return confMainTitle;
	}
	
	getConfSecTitle(){
		return confSecTitle;
	}
	
	loadIntro(){
		ReactDOM.render(
			e(Intro), document.getElementById('root_')
		);	
	}
	
	loadUIAllStations(){
		this.loadNavStart();
		ReactDOM.render(
				<ALLP list={listAllStations} listname='Alle Pegel' />, document.getElementById('content_')
		);
	}	
	
	loadUIHWMP(list, listname){
		
		this.loadNavStart();
		ReactDOM.render(
			<HWMP list={list} listname={listname} />, document.getElementById('content_')
		);				
	}	

	loadPrognose(){
		ReactDOM.render(
			<PROGELBE />, document.getElementById('content_')
		);
		
	}
	
	loadUIStationInformation(list, index, listname){
		this.loadNavStation(list, index, listname);

		ReactDOM.render(
		  <StationInformations	list = {list} index={index} listname={listname}/>, document.getElementById('content_')
		);
		
	}
	
	
}


export default new Functions();



