import React, { Component } from 'react';



class Alarm extends React.Component {
	componentDidMount() {
    }
    render() {   
	return (		
		<div class="as-info-view">
			<div class=""> 
				<div class="">
					<div class="navbar-brand">Richtwertüberschreitungen</div>
					<div class="font-weight-light pl-2 border-bottom " style={{fontSize:12}} >an den HW-Meldepegeln nach HWMO ST</div>
					<div id="overview" class="" style={{position:"relative", top:10}}></div>
				</div>
			</div>
			
			<div className="" style={{margin:'20px auto', maxWidth:250}}>
				
				<div class="d-flex pt-2 pl-2">
					<img class="aicon"  style={{backgroundColor: '#88ccff', borderRadius:10, border: '2px solid #262626'}} src={'./icons/mg_icon_small.png'}/>
					<div class="text-left my-auto pl-2">Meldegrenze</div>			  
				</div>

				<div class="d-flex pt-2 pl-2">
					<img   class="aicon"  style={{backgroundColor: '#ff0', borderRadius:10, border: '2px solid #262626'}} src={'./icons/aone_icon_small.png'}/>
					<div class="text-left my-auto pl-2">Alarmstufe 1</div>			  
				</div>

				<div class="d-flex pt-2 pl-2">
					<img class="aicon"  style={{backgroundColor: '#f80', borderRadius:10, border: '2px solid #262626'}} src={'./icons/atwo_icon_small.png'}/>
					<div class="text-left my-auto pl-2">Alarmstufe 2</div>			  
				</div>

				<div class="d-flex pt-2 pl-2">
					<img class="aicon"  style={{backgroundColor: '#f00', borderRadius:10, border: '2px solid #262626'}} src={'./icons/athree_icon_small.png'}/>
					<div class="text-left my-auto pl-2">Alarmstufe 3</div>			  
				</div>

				<div class="d-flex pt-2 pl-2">
					<img class="aicon"  style={{backgroundColor: '#f0f', borderRadius:10, border: '2px solid #262626'}}  src={'./icons/afour_icon_small.png'}/>
					<div class="text-left my-auto pl-2">Alarmstufe 4</div>			  
				</div>
			</div>
		</div>
		
    );
  }
}

			
export default Alarm;