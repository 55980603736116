import React, { Component } from 'react';


					
						
					
class NavStart extends React.Component {

	getCalledAlert=()=>{
		(async () => {      
		  const response = await fetch('./data/documents/misc/alarmmeldungen.json');
		  let json = await response.json();      		 
		})();
		 
		
	  }


	componentDidMount() {
		this.getCalledAlert();
	}
	


    render() {  
		let bgcolor_mg ='#252525';
		let color_mg ='white';

		let bgcolor_as1 ='#252525';
		let color_as1 ='white';

		let bgcolor_as2 ='#252525';
		let color_as2 ='white';

		let bgcolor_as3 ='#252525';
		let color_as3 ='white';

		let bgcolor_as4 ='#252525';
		let color_as4 ='white';
		

		if(  this.props.app.state.listMg.length > 0){
			bgcolor_mg = '#00f';
			color_mg ='white';
		}			
			
		if(  this.props.app.state.listAs1.length > 0){
			bgcolor_as1 ='#ff0';
			color_as1 ='#252525';				
		}
			
		if(  this.props.app.state.listAs2.length > 0){
			bgcolor_as2 ='#f80';
			color_as2 ='white';			
		}
			
		if(  this.props.app.state.listAs3.length > 0){
			bgcolor_as3 ='#f00';
			color_as3 ='white';			
		}
			
		if(  this.props.app.state.listAs4.length > 0){
			bgcolor_as4 ='#f0f';
			color_as4 ='white';			
		}	
			
		return (
			/* getNavStart() */
			<div class="navigation text-center p-1  d-flex justify-content-center mw-100 ">
			<div 
				id="mg_nav"
				class="buttonnav mx-auto  text-white pb-1"
				
				style={{width:'45px', height:'45px', backgroundColor: bgcolor_mg, color: color_mg}}
				
				onClick={()=>{
						//$('#mg_nav').css('background-color','#00f');	
						if(this.props.app.state.listMg.length > 0){
							this.props.app.loadUIHWMP(this.props.app.state.listMg, 'Meldegrenze');
						}
					}}>
				
				<div id="txt_mg_nav" class="buttontext">MG</div>
				<div id="cnt_mg_nav" class="mx-auto font-weight-bold" style={{color: color_mg}}>{this.props.app.state.listMg.length}</div>
			</div>

			<div 
				id="as1_nav"
				class="buttonnav mx-auto  text-white pb-1"
				style={{width:'45px', height:'45px', backgroundColor: bgcolor_as1, color: color_as1}}
				onClick={()=>{										
						if(this.props.app.state.listAs1.length!=0){
							this.props.app.loadUIHWMP(this.props.app.state.listAs1, 'Alarmstufe 1');
						}
					}}>
				
				<div id="txt_as1_nav" class="buttontext" style={{color: color_as1}}>AS1</div>
				<div id="cnt_as1_nav" class="mx-auto font-weight-bold" style={{color: color_as1}}>{ this.props.app.state.listAs1.length}</div>
			</div>

			<div 
				id="as2_nav"
				class="buttonnav mx-auto  text-white pb-1"
				style={{width:'45px', height:'45px', backgroundColor: bgcolor_as2, color: color_as2}}
				onClick={()=>{										
						if(this.props.app.state.listAs2.length!=0){
							this.props.app.loadUIHWMP(this.props.app.state.listAs2, 'Alarmstufe 2');
						}
					}}>
				
				<div id="txt_as2_nav" class="buttontext" style={{color: color_as2}}>AS2</div>
				<div id="cnt_as2_nav" class="mx-auto font-weight-bold" style={{color: color_as2}}>{ this.props.app.state.listAs2.length}</div>
			</div>

			<div 
				id="as3_nav"
				class="buttonnav mx-auto  text-white pb-1"
				style={{width:'45px', height:'45px', backgroundColor: bgcolor_as3, color: color_as3}}
				onClick={()=>{										
						if(this.props.app.state.listAs3.length!=0){
							this.props.app.loadUIHWMP(this.props.app.state.listAs3, 'Alarmstufe 3');
						}
					}}>
				
				<div id="txt_as3_nav" class="buttontext" style={{color: color_as3}}>AS3</div>
				<div id="cnt_as3_nav" class="mx-auto font-weight-bold" style={{color: color_as3}}>{ this.props.app.state.listAs3.length}</div>
			</div>

			<div 
				id="as4_nav"
				class="buttonnav mx-auto  text-white pb-1"
				style={{width:'45px', height:'45px', backgroundColor: bgcolor_as4, color: color_as4}}
				onClick={()=>{										
						if(this.props.app.state.listAs4.length!=0){
							this.props.app.loadUIHWMP(this.props.app.state.listAs4, 'Alarmstufe 4');
						}
					}}>
				
				<div id="txt_as4_nav" class="buttontext" style={{color: color_as4}}>AS4</div>
				<div id="cnt_as4_nav" class="mx-auto font-weight-bold" style={{color: color_as4}}>{ this.props.app.state.listAs4.length}</div>
			</div>							
		</div>
	)
  }
}

export default NavStart;					