import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Chart from './CreateChart';








class StationInformations extends React.Component {
	constructor(props) {
		super(props);
		
	}
		
	componentDidMount(){
		/* this.loadWCahrt(this.props.index)
		this.loadQCahrt(this.props.index) */
	}
	
	componentDidUpdate(){
		//console.log('update stainfo: ' + this.props.index)
			
		//this.loadStationInfomations(this.props.list, this.props.index, this.props.listname);
	}
	

	loadWCahrt = (index)=>{	 
		var ctx = document.getElementById("wChart").getContext('2d');
		
		var annotations = [];
		var label_backgroundColor = "#ff0000";
		var label_position =  "left";
		var label_fontColor =  "#fff";
		var label_fontSize = 10;
		var label_fontStyle =  "normal";
		var label_cornerRadius = 0;
		var label_xPadding = 0;
		var label_yPadding = 0;
		var label_xAdjust_mg =  5;
		var label_xAdjust_as1 =  5;
		var label_xAdjust_as2 =  40;
		var label_xAdjust_as3 =  5;
		var label_xAdjust_as4 =  40;
	
		var label_yAdjust =  0;
		var label_enabled =  true;
		
		if(this.props.list[index].w_as != null){
			if(this.props.list[index].w_as.mg != null){						
				annotations.push({
					label: {
						backgroundColor: label_backgroundColor,
						position: label_position,
						fontColor: label_fontColor,
						fontSize: label_fontSize,
						cornerRadius: label_cornerRadius,
						xPadding:label_xPadding,
						yPadding:label_yPadding,
						xAdjust:label_xAdjust_mg,
						yAdjust: label_yAdjust,
						fontStyle: label_fontStyle,
						enabled: label_enabled,
						content: "MG"
						
					  },
					type: 'line',
					mode: 'horizontal',
					scaleID: 'y-axis-0',
					value: this.props.list[index].w_as.mg.value,						
					borderColor: '#0000ff',
					borderWidth: 2
				});				
			}
			
			if(this.props.list[index].w_as.as1 != null){						
				annotations.push({
					label: {
						backgroundColor: label_backgroundColor,
						 position: label_position,
						 fontColor: label_fontColor,
						 fontSize: label_fontSize,
						 cornerRadius: label_cornerRadius,
						 xPadding:label_xPadding,
						 yPadding:label_yPadding,
						 yAdjust: label_yAdjust,
						 xAdjust:label_xAdjust_as1,
						 fontStyle: label_fontStyle,
						 enabled: label_enabled,
						content: "AS1"
					},
					type: 'line',
					mode: 'horizontal',
					scaleID: 'y-axis-0',
					value: this.props.list[index].w_as.as1.value,						
					borderColor: '#ffff00',
					borderWidth: 2
				});						
			}
			if(this.props.list[index].w_as.as2 != null){	
				annotations.push({label: {
						backgroundColor: label_backgroundColor,
						position: label_position,
						fontColor: label_fontColor,
						fontSize: label_fontSize,
						cornerRadius: label_cornerRadius,
						xPadding:label_xPadding,
						yPadding:label_yPadding,
						xAdjust:label_xAdjust_as2,
						yAdjust: label_yAdjust,
						fontStyle: label_fontStyle,
						enabled: label_enabled,
						content: "AS2"
					},
					type: 'line',
					mode: 'horizontal',
					scaleID: 'y-axis-0',
					value: this.props.list[index].w_as.as2.value,						
					borderColor: '#ff7f00',
					borderWidth: 2
				});
			}
			
			if(this.props.list[index].w_as.as3 != null){	
				annotations.push({label: {
						 backgroundColor: label_backgroundColor,
						 position: label_position,
						 fontColor: label_fontColor,
						 fontSize: label_fontSize,
						 cornerRadius: label_cornerRadius,
						 xPadding:label_xPadding,
						 yPadding:label_yPadding,
						 xAdjust:label_xAdjust_as3,
						 yAdjust: label_yAdjust,
						 fontStyle: label_fontStyle,
						 enabled: label_enabled,
						 content: "AS3"
						 
					  },
					type: 'line',
					mode: 'horizontal',
					scaleID: 'y-axis-0',
					value: this.props.list[index].w_as.as3.value,						
					borderColor: '#ff0000',
					borderWidth: 2
				});	
			}
			
			if(this.props.list[index].w_as.as4 != null){	
				annotations.push({
						label: {					
							backgroundColor: label_backgroundColor,
							position: label_position,
							fontColor: label_fontColor,
							fontSize: label_fontSize,
							cornerRadius: label_cornerRadius,
							xPadding:label_xPadding,
							yPadding:label_yPadding,
							xAdjust:label_xAdjust_as4,
							yAdjust: label_yAdjust,
							fontStyle: label_fontStyle,
							enabled: label_enabled,
							content: "AS4"
					  },
					type: 'line',
					mode: 'horizontal',
					scaleID: 'y-axis-0',
					value: this.props.list[index].w_as.as4.value,						
					borderColor: '#ff00ff',
					borderWidth: 2
				});
			}
			
			
			var ymax = 0;
			
			if(this.props.list[index].w_as.as4 != null){
				ymax = this.props.list[index].w_as.as4.value + 25;
			}else{
				if(this.props.list[index].w_as.as3 != null){
					ymax = this.props.list[index].w_as.as3.value + 25;
				}else{
					if(this.props.list[index].w_as.as2 != null){
						ymax = this.props.list[index].w_as.as2.value + 25;
					}else{
						if(this.props.list[index].w_as.as1 != null){
							ymax = this.props.list[index].w_as.as1.value + 25;
						}else{
							if(this.props.list[index].w_as.mg != null){
								ymax = this.props.list[index].w_as.mg.value + 25;
							}
						}
					}
				}
			
			}
				
			//////console.log('ymax :' + ymax);	
			annotations.push({
				type: 'line',
				mode: 'horizontal',
				scaleID: 'y-axis-0',
				value: 	ymax,					
				borderColor: label_backgroundColor,
				borderWidth: 0,
				borderDash: [1, 10],
			});
			
					
		}
		
		////console.log('hier:');
		////console.log(this.props.list[index].values)
		var wChart = new Chart(ctx, {
			type: 'line',
			data: {
				//labels: list[index].tss,			
				datasets: [{
					label: 'Wasserstand',
					data: this.props.list[index].values,
					fill: true,
					showLine: true,
					borderCapStyle:'butt',
					borderJoinStyle: 'round',
					borderWidth: 2,
					pointRadius: 0,
					lineTension: 0,
					backgroundColor: 'rgb(52,58,64)',
							borderColor: 'rgba(0, 90, 255, 1)'			
				}/*,{
					label: 'Vorhersage',
					data: list[index].values_prog,
					//borderDash: [4,2],					
					fill: false,
					showLine: true,
					borderCapStyle:'butt',
					borderJoinStyle: 'round',
					borderWidth: 1,
					pointRadius: 0,
					lineTension: 0,
					backgroundColor: '#0071BC',
					borderColor: '#0071BC'			
				},{
					label: 'min',
					data: list[index].values_prog_min,
					borderDash: [2,4],					
					fill: false,
					showLine: true,
					borderCapStyle:'butt',
					borderJoinStyle: 'round',
					borderWidth: 1,
					pointRadius: 0,
					lineTension: 0,
					backgroundColor: '#0071BC',
					borderColor: '#232323'			
				},{
					label: 'max',
					data: list[index].values_prog_max,
					borderDash: [2,4],					
					fill: false,
					showLine: true,
					borderCapStyle:'butt',
					borderJoinStyle: 'round',
					borderWidth: 1,
					pointRadius: 0,
					lineTension: 0,
					backgroundColor: '#0071BC',
					borderColor: '#232323'			
				},{
					label: 'Abschätzung',
					data: list[index].values_est,
					borderDash: [4,2],					
					fill: false,
					showLine: true,
					borderCapStyle:'butt',
					borderJoinStyle: 'round',
					borderWidth: 1,
					pointRadius: 0,
					lineTension: 0,
					backgroundColor: '#0071BC',
					borderColor: '#0071BC'			
				}
				,{
					label: 'max',
					data: list[index].values_est_min,
					borderDash: [2,4],					
					fill: false,
					showLine: true,
					borderCapStyle:'butt',
					borderJoinStyle: 'round',
					borderWidth: 1,
					pointRadius: 0,
					lineTension: 0,
					backgroundColor: '#0071BC',
					borderColor: '#232323'			
				}
				,{
					label: 'max',
					data: list[index].values_est_max,
					borderDash: [2,4],					
					fill: false,
					showLine: true,
					borderCapStyle:'butt',
					borderJoinStyle: 'round',
					borderWidth: 1,
					pointRadius: 0,
					lineTension: 0,
					backgroundColor: '#0071BC',
					borderColor: '#232323'			
				}*/
				]
			},
			options: {
				
				animation : false,
				responsive: true,
				legend:{
					display: false
				},
				scales: {
					xAxes: [{
						type: 'time',
						
						distribution: 'series',
						time: {
							unit: 'day',
							displayFormats: {
								day: 'DD.MM'
							}
						},
						ticks:	{
							fontColor: '#fff',
							beginAtZero:false,
							maxTicksLimit: 10
						},
						gridLines:{
							color: 'rgba(255, 255, 255, 0.2)'
						},
						scaleLabel: {
							fontColor: 'rgba(255, 255, 255, 1)',
							display: true,
							labelString: 'Datum'
						}
					}],
					yAxes: [{
						ticks:	{
							fontColor: '#fff',
							beginAtZero:true,
							maxTicksLimit: 10
							
						},
						gridLines:{
							color: 'rgba(255, 255, 255, 0.2)'
						},
						scaleLabel: {
							fontColor: 'rgba(255, 255, 255, 1)',
							display: true,
							labelString: 'W [cm]'
						}
					}]
				},
				elements: { point: { radius: 0 } },			     
				annotation: {
					annotations: annotations,
					drawTime: "afterDraw" // (default)
				}
			}
		});
	}
		
		
	loadQCahrt = (index)=>{	
		///////
		if(this.props.list[index].qvalue != null){
			
				var ctx = document.getElementById("qChart").getContext('2d');
				var qChart = new Chart(ctx, {
					type: 'line',
					
					data: {
						//labels: this.props.list[index].qtss,
						spanGaps: false,
						datasets: [{
							label: 'Durchfluss',
							data: this.props.list[index].qvalues,
							fill: true,
							showLine: true,
							borderCapStyle:'butt',
							borderJoinStyle: 'round',
							borderWidth: 2,
							pointRadius: 0,
							lineTension: 0,
							backgroundColor: 'rgb(52,58,64)',
							borderColor: 'rgba(0, 90, 255, 1)'											
						}]
					},
					options: {
						animation : false,
						responsive: true,
						legend:{
							display: false
						},
						scales: {
							xAxes: [{
								type: 'time',
								distribution: 'series',
								time: {
									unit: 'day',
									displayFormats: {
									day: 'DD.MM'
									}
								},
								ticks:	{
									fontColor: '#fff',
									maxTicksLimit: 10
								},
								gridLines:{
									color: 'rgba(255, 255, 255, 0.2)'
								},
								scaleLabel: {
									fontColor: 'rgba(255, 255, 255, 1)',
									display: true,
									labelString: 'Datum'
								}
							}],
							yAxes: [{
								ticks:	{
									fontColor: '#fff',
									beginAtZero:true,
									maxTicksLimit: 5
									//stepSize: 1
								},
								gridLines:{
									color: 'rgba(255, 255, 255, 0.2)'
								},
								scaleLabel: {
									fontColor: 'rgba(255, 255, 255, 1)',
									display: true,
									labelString: 'Q [m³/s]'
								}
							}]
						},
						elements: { point: { radius: 0 } }
					}
				});
			
		
		}
		
		
		}		
		
		
		////////

		loadNextStationInfo=(index)=>{
		
			var newindex = 0;
			
			if(index < 0 ){			
				newindex = this.props.list.length - 1;
			}else{
				if(index > (this.props.list.length - 1)){
					newindex = 0;
				}else{
					newindex = index;
				}
			}		
	
			//console.log(this.props.backbtn)
			this.props.app.loadUIStationInformation(this.props.list, this.props.list[newindex].stano, this.props.listname, this.props.backbtn);
		}	
		
	componentDidMount(){
		/* ReactDOM.render(
			<NavStation app={this.props.app} list={this.props.list} index = {this.props.index} listname={this.props.listname} backbtn={this.props.backbtn}/>, document.getElementById('sta_footer')
		); */

		ReactDOM.render(
			<div id="" class="side-button-left"		
			
				onClick={(e)=>{										
					this.loadNextStationInfo(this.props.index-1);						
				}}
				>
					
					 <img class="side-image"    src="./icons/arrow_left.png" /> 
					 
				</div>,
				document.getElementById('ctrlLeft')
		)

		ReactDOM.render(
			<div class="side-button-right"  
			
				onClick={(e)=>{											
					this.loadNextStationInfo(this.props.index+1);	
				}}
				>
					<img class="side-image"   src="./icons/arrow_right.png" />
			</div>,
			document.getElementById('ctrlRight'))
	}

    render() { 
		var index =  this.props.index;
		/* console.log(this.props.list)
		console.log(index)
		console.log(this.props.list[index].w_as.as1) */

		
		

		var stagemg =[];
		var stageas1 =[];
		var stageas2 =[];
		var stageas3 =[];
		var stageas4 =[];

		if(this.props.list[index].w_as.mg !=  null){
			stagemg = ['MG', this.props.list[index].w_as.mg.value, '#00f'];
			console.log(stageas1)
		}
		
		if(this.props.list[index].w_as.as1 !=  null){
			stageas1 = ['AS1', this.props.list[index].w_as.as1.value, '#ff0'];
			console.log(stageas1)
		}
		
		if(this.props.list[index].value != null && this.props.list[index].w_as.as1 != null){
			if(this.props.list[index].value > this.props.list[index].w_as.as1.value){
				if(this.props.list[index].w_as.as2 !=  null){
					stageas2 = ['AS2', this.props.list[index].w_as.as2.value, '#f80'];
				}
			}
		}

		if(this.props.list[index].value != null && this.props.list[index].w_as.as2 != null){
			if(this.props.list[index].value > this.props.list[index].w_as.as2.value){
				if(this.props.list[index].w_as.as3 !=  null){
					stageas3 = ['AS3', this.props.list[index].w_as.as3.value, '#f00'];
				}
			}
		}
		if(this.props.list[index].value != null && this.props.list[index].w_as.as3 != null){
			if(this.props.list[index].value > this.props.list[index].w_as.as3.value){
				if(this.props.list[index].w_as.as4 !=  null){
					stageas4 = ['AS4', this.props.list[index].w_as.as4.value, '#f0f'];
				}
			}	
		}				
			
		
		
		return (
			<div class="">
				
			

 <div className="header-nonflex">
				<div class=" navbar-brand header-container" >
					<div class="rounded  my-auto " onClick={
						(e)=>{this.props.app.loadUIHWMP(this.props.list, this.props.listname, this.props.backbtn)}
					}>
						<img class="" src="./icons/arrow_left.png" style={{width:'35px'}}/>
					</div>

					<div class="header-text">{this.props.list[index].staname}</div>
						
					
					<div class="my-auto" onClick={(e)=>{this.props.app.showModalMenue()}}>
					<img src="./icons/menue_points.png" class="" style={{width:40}}/>	 
					</div>
				</div>
				</div>

				<div id="ctrlLeft" class="ctrl-left"></div>
				<div id="ctrlRight" class="ctrl-right" ></div>



				<div id="aktuell" class="text-dark ">
					{/* <div class="text-center text-white h4">{this.props.list[index].staname}</div> */}
					
					{/* ////////////Wasserstand////////////////// */}
					<div id="w_aktuell" class="shadow-box" >
						<div class="px-3 my-1" style={{fontWeight:'bold'}}>Wasserstand</div>

						{
							(this.props.list[index].value != null)?
							<div>
								<div class="d-flex">
									<div class="px-4 font-weight-bold">{this.props.list[index].value + ' ' + this.props.list[index].unit }</div>	
									<div class="px-4">{'(' + this.props.list[index].ts + ')'}</div>
								</div>
								<div class="px-3 my-1 ">Einstufung</div>
								{/* <div class="my-auto mx-4" style={{backgroundColor: this.props.list[index].state.classification, height: 20, width:20}}></div> */}
								<img class="mx-4 aicon" style={{backgroundColor: this.props.list[index].state.classification, borderRadius:(this.props.list[index].state.edge- 4), border: '2px solid #262626'}}  src={'./icons/' + this.props.list[index].state.img + '_icon_small.png'}/>
							</div>
							:
							<div class="px-4 font-weight-bold">Derzeit keine Daten vorhanden </div>
						}

						{
							(this.props.list[index].values.length != 0)?
							<div>
								<div class="px-3 my-1">5-Tage-Rückblick</div>
								<div class="px-2  mb-4">	
									<Chart typ={'line'} label={'W' + ' [' + this.props.list[index].unit + ']'} data={this.props.list[index].values}  stages={[stagemg, stageas1, stageas2, stageas3, stageas4]}/>							
								</div>
							</div>
							:null
						}	
					</div>	

				
					{/* ////////////////////Durchfluss////////////////////////// */}
					{
						(this.props.list[index].qvalue != null)?
							<div id="q_aktuell" class="shadow-box" >
								<div class="px-3 my-1" style={{fontWeight:'bold'}}>Durchfluss</div>
								<div class="d-flex">
									<div class="px-4 font-weight-bold">{this.props.list[index].qvalue + ' ' + this.props.list[index].qunit}</div>	
									<div class="px-4">{'(' + this.props.list[index].qts + ')'}</div>
								</div>
								<div class="px-3 my-1">5-Tage-Rückblick</div>
								{/* ////chart */}
								<div class="px-2  mb-4">
									
									<Chart  typ={'line'} label={'Q' + ' [' + this.props.list[index].qunit + ']'} data={this.props.list[index].qvalues}  stages={this.props.list[index].staas1}/>
									
								</div>
							</div>
						:null
					}

					
				

				<div id="info" class="shadow-box" >
				
					<div id="station_nr" class="px-3">Stationsnummer</div>
					<div class="px-4 font-weight-bold">{this.props.list[index].stano}</div>
					<div id="station_name" class="px-3">Stationsname</div>
					<div class="px-4 font-weight-bold">{this.props.list[index].staname}</div>
					<div id="station_gewaesser" class="px-3">Gew&auml;sser</div>
					<div class="px-4 font-weight-bold">{this.props.list[index].waterobj}</div>
					<div id="station_betreiber" class="px-3">Betreiber</div>
					<div class="px-4 font-weight-bold">{this.props.list[index].sitename}</div>
				
				{
					(this.props.list[index].w_as != null)?
						(this.props.list[index].state.hwmp)?
						<div class=" px-3">
							<div>{'Richtwerte [' + this.props.list[index].unit +']' }</div>
							<div class="d-flex flex-wrap">
							{
								(this.props.list[index].w_as.mg != null)?
									<div>
										<div id="mg" class="px-3 d-row">MG</div>
										<div class="px-4 font-weight-bold">{this.props.list[index].w_as.mg.value}</div> 
									</div>
								:null
							}
							{	
								(this.props.list[index].w_as.as1 !=  null)?
								<div>
									<div id="as1" class="px-3 d-flex d-row">AS1</div>
									<div class="px-4 font-weight-bold">{this.props.list[index].w_as.as1.value}</div>
								</div>
							:null
							}
							{
							(this.props.list[index].w_as.as2 !=  null)?
								<div>
									<div id="as2" class="px-3 d-flex d-row">AS2</div>
									<div class="px-4 font-weight-bold">{this.props.list[index].w_as.as2.value}</div>
								</div>
							:null
							}
							{
							(this.props.list[index].w_as.as3 !=  null)?
								<div>
									<div id="as3" class="px-3 d-flex d-row">AS3</div>
									<div class="px-4 font-weight-bold">{this.props.list[index].w_as.as3.value}</div>
								</div>
							:null
							}
							{
							(this.props.list[index].w_as.as4 !=  null)?
								<div>
									<div id="as4" class="px-3 d-flex d-row">AS4</div>
									<div class="px-4 font-weight-bold">{this.props.list[index].w_as.as4.value}</div>
								</div>
							:null
							}
							</div>
						</div>
						:null
					:null
						}				
				</div>	
				</div>	

				<div id="sta_footer" class="fixed-bottom mx-auto " style={{bottom:20, maxWidth:960}}></div> 	
			</div>	
		);
  }
}

export default StationInformations;
