import React, { Component } from 'react';
			
class App extends React.Component {
	constructor(props){
		super(props);
		this.state={
			result:<></>
		}
	}
	componentDidMount() {

		
		let catchments = []
		let catchmentJson = []

		fetch('./data/documents/Hochwasserwarnungen/index.json')
  			.then(response => response.json())
			.then(data => {
				
				data.map(item=>{
					catchments.push(item.catchment);
					catchmentJson.push('{"catchment":"' 
									+ item.catchment 
									+ '", "file":"./data/documents/Hochwasserwarnungen/'+ item.filename 
									+ '", "filename":"'
									+ item.filename 
									+ '"}');

				})
					

									console.log(catchments)
			let uniqueCatchments = Array.from(new Set(catchments));
			
			if(uniqueCatchments.length > 0){
	
				let result = <ul>
					{
					uniqueCatchments.map((item, key)=>(
						
						<li onClick={()=>{
							this.props.app.loadHwwSide(uniqueCatchments, catchmentJson);
							this.props.app.onHide();
						}}>{item}</li>
						
					))	
				}
				</ul>

				this.setState({
					result: result
				})
	
			}




			  })
			  .catch(function(err) {
				console.log(err);
			});			







			
		} 
	render() { 
			return <div id ="div_content">
				<div class="mx-4 alert alert-warning  text-dark " style={{borderRadius: 5, cursor:'pointer'}}>
					<div id="div"> Es liegen HW-Warnungen vor</div>
					<div id="result">{this.state.result}</div>
				</div>
			</div>}
}

export default App;