import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery"; 
import moment from "moment";
import PDFView from './PDFView';


export default class ProgElbe extends React.Component {
	componentDidMount(){		
		var path = './data/documents/Vorhersagen/';	
			$.get(path + 'index.json').then((response)=>{
							
				var pdfs = <div>
								{
									response.reverse().map((item)=>(
										
									<div 						
										class="menue-btn" 
										
										
										onClick={()=>{
											ReactDOM.render(
												<PDFView app = {this.props.app} mainpath={path} document={path + item.filename} title={'Elbe-Vorhersage'} back={(e)=>this.props.app.loadPrognose()} date={moment(item.lastModified).format("DD.MM.YYYY").toString()}/>, document.getElementById('content_')
											);
											
										}}
										>
											{
											console.log(item)
										}
										{moment(item.lastModified).format("DD.MM.YYYY").toString()}
									</div>			
									))	
								}
							</div>
					

				ReactDOM.render(pdfs, document.getElementById('ul_prog'))											
			})								
		

	}

	
			 
	
	render() { 
		
		return (
			
			<div class="text-center">	
				<div class=" navbar-brand header-container" >
					
					
					<div class="my-auto " onClick={					
						(e)=>this.props.app.loadStartside()
					}>
						<img class="" src="./icons/arrow_left.png" style={{width:'35px'}}/>	
					</div>

					
					<div class="header-text">Elbe-Vorhersage</div>
						
					
					<div class="my-auto" onClick={(e)=>{this.props.app.showModalMenue()}}>
					<img src="./icons/menue_points.png" class="" style={{width:40}}/>	 
					</div>
				</div>		

				<div  id="ul_prog" class="" >	
								
				</div>
			</div>
			
		);
	}
}


