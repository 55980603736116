import React, { Component } from 'react';
import ReactDOM from 'react-dom';
const e = React.createElement;



class Intro extends React.Component {
	componentDidMount() {
			
    }
    render() {   
		return (
			<div className="intro-container" style={{maxWidth:960}}>
				<div class="mx-auto text-center w-100" style={{
							backgroundColor:'#fff', borderBottom:'1px solid #393E41', color:'#393E41'
						}}>
					<div>HOCHWASSER-VORHERSAGE-ZENTRALE</div>
					<div class="h5 font-weight-bold">HVZ</div>
					<div>Sachsen-Anhalt (ST)</div>
				</div>

				<div class=" my-auto mx-auto text-center" style={{color:'#393E41'}}>
					<div class="h5 font-weight-bold">Daten werden geladen.</div>							
					<div class="h6">Bitte gedulden Sie sich noch einen Augenblick.</div>
					<div class="pulse"></div>
				</div>

				<div class=""  style={{
					textAlign:'center',
					padding:'20px 0px',
					backgroundColor:'#fff', 
					borderTop:'1px solid #393E41',
					color:'#cdcdcd'
				}}>	
								   
				  <img className="img-fluid " src="./icons/logo.png " width="200px"/> 
          <div style={{
							backgroundColor:'#fff', color:'#393E41'}}
						>					
          	Landesbetrieb für Hochwasserschutz und Wasserwirtschaft Sachsen-Anhalt
          </div>
        
				</div>
				
			</div>			
		);
	}
}
			
export default Intro;