import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";

import Functions from './functions';
const e = React.createElement;

var span = '<span style="font-size: 16px; font-weight: normal;">';
var	span_klein = '<span style="font-size: 12px; font-weight: normal;">';
var	span_bold = '<span style="font-size: 16px; font-weight: bold;">';

function loadImpressum(){
  /* $('<div/>').html(span_bold + 'Ministerium für Umwelt, Landwirtschaft und Energie des Landes Sachsen-Anhalt ' + '</span>').appendTo('#impressumcontent');
  $('<div/>').attr('class','px-0 ').html('Leipziger Str. 58 ').appendTo('#impressumcontent');
  $('<div/>').attr('class','px-0').html('39112 Magdeburg ').appendTo('#impressumcontent');

  $('<div/>').attr('class','px-2 mt-3 text-white').html('Telefon').appendTo('#impressumcontent');
  $('<div/>').attr('class','px-3 ').html(span  + '+49 391 567-01'  + '</span>').appendTo('#impressumcontent');  

  $('<div/>').attr('class','px-2 text-white').html('Telefax').appendTo('#impressumcontent');
  $('<div/>').attr('class','px-3').html(span  + '+49 391 567-1727'  + '</span>').appendTo('#impressumcontent');
  
  $('<div/>').attr('class','px-2 text-white').html('E-Mail').appendTo('#impressumcontent');
  $('<div/>').attr('class','px-3 ').html(span  + 'poststelle@mule.sachsen-anhalt.de'  + '</span>').appendTo('#impressumcontent');
   */

  $('<div/>').attr('id','impressumcontent_home').attr('class', 'p-2 mb-4 bg-dark').attr('style','border-radius: 10px').appendTo('#impressumcontent');
    $('<div/>').attr('class', 'font-weight-bold').html('Landesbetrieb für Hochwasserschutz und Wasserwirtschaft Sachsen-Anhalt').appendTo('#impressumcontent_home');
    
    $('<div/>').attr('class','px-0 font-weight-bold').html('Otto-von-Guericke-Straße 05').appendTo('#impressumcontent_home');
    $('<div/>').attr('class','px-0 font-weight-bold ').html('39104 Magdeburg ').appendTo('#impressumcontent_home');

    $('<div/>').attr('class','px-2 mt-3 text-white').html('Telefon').appendTo('#impressumcontent_home');
    $('<div/>').attr('class','px-3 font-weight-bold').html('+49 391 581-0').appendTo('#impressumcontent_home');  

    $('<div/>').attr('class','px-2 text-white').html('Telefax').appendTo('#impressumcontent_home');
    $('<div/>').attr('class','px-3 font-weight-bold').html('+49 391 581-1230').appendTo('#impressumcontent_home');
    
    $('<div/>').attr('class','px-2 text-white').html('E-Mail').appendTo('#impressumcontent_home');
    $('<div/>').attr('class','px-3 mb-3 font-weight-bold').html('poststelle@lhw.mlu.sachsen-anhalt.de').appendTo('#impressumcontent_home');





  $('<div/>').attr('id','impressumcontent_sec').attr('class', 'p-2 bg-dark').attr('style','border-radius: 10px').appendTo('#impressumcontent');
  $('<div/>').attr('class', 'font-weight-bold').html('Datenschutzbeauftragter').appendTo('#impressumcontent_sec');
    $('<div/>').attr('class','px-1 text-white').html('Name').appendTo('#impressumcontent_sec');
    $('<div/>').attr('class','px-3 font-weight-bold').html('Martin Hünecke').appendTo('#impressumcontent_sec');
  
  $('<div/>').attr('class','px-1 text-white ').html('Adresse').appendTo('#impressumcontent_sec');
    $('<div/>').attr('class','px-3 font-weight-bold').html('Otto-von-Guericke-Straße 5').appendTo('#impressumcontent_sec');
    $('<div/>').attr('class','px-3 font-weight-bold').html('39104 Magdeburg' ).appendTo('#impressumcontent_sec');

  $('<div/>').attr('class','px-1 text-white ').html('Telefon').appendTo('#impressumcontent_sec');
    $('<div/>').attr('class','px-3 font-weight-bold').html('+49 391 581 1255').appendTo('#impressumcontent_sec');
  
  $('<div/>').attr('class','px-1 text-white').html('E-Mail').appendTo('#impressumcontent_sec');
    $('<div/>').attr('class','px-3 mb-3 font-weight-bold').html('datenschutz@lhw.mlu.sachsen-anhalt.de').appendTo('#impressumcontent_sec');




  $('<div/>').attr('class','mt-5 text-dark').html(span_bold + 'wichtiger Hinweis:' + '</span>').appendTo('#impressumcontent');
  $('<div/>').attr('class','text-dark').html(span + ' Für die von uns verlinkten Fremdangebote sind die jeweiligen Herausgeber verantwortlich.'
                                              +' Das Kopieren und Verwenden unseres Foto- und Grafikangebotes'
                                              +' ist nur mit Genehmigung zulässig. ' + '</span>').appendTo('#impressumcontent');
        
		
}



var impressumside = 	e('div',{className:'p-4 text-dark text-center'},
					e('div',{className:'navbar-brand  mb-4'}, 
						e('h4',{},'Impressum')					
					),				
				e('div',{className:'d-flex', onClick:function(){}},
					 e('div',{id:'impressumcontent', className:'flex-grow-1'})
				)		
	);
	
			



class ImpressumSide extends React.Component {
		
	componentDidMount(){
		loadImpressum()
	}
	
    render() { 	
		return (
      <div class="py-2 text-white text-center">
        <div class=" navbar-brand header-container">
        
        
        <div class="my-auto " onClick={					
          (e)=>this.props.app.loadStartside()
        }>
         <img class="" src="./icons/arrow_left.png" style={{width:'35px'}}/>
        </div>

        
        <div class="header-text">Impressum</div>
          
        
        <div class="my-auto" onClick={(e)=>{this.props.app.showModalMenue()}}>
					<img src="./icons/menue_points.png" class="" style={{width:40}}/>	 
					</div>
      </div>
          
			{/* 
				<div>
					<div id="" class="h5">Impressum</div>
				</div> */}

				<div class="shadow-box">

					<div class="font-weight-bold">Landesbetrieb für Hochwasserschutz und Wasserwirtschaft Sachsen-Anhalt</div>
					<div class="px-1 pt-2 font-weight-bold">Adresse</div>
          <div class="px-3">Otto-von-Guericke-Straße 05</div>
          <div class="px-3">39104 Magdeburg </div>

          <div class="px-1 pt-2 font-weight-bold">Telefon</div>
					<div class="px-3">+49 391 581-0</div>

          <div class="px-1 pt-2 font-weight-bold">Telefax</div>
					<div class="px-3">+49 391 581-1230</div>
					
					<div class="px-1 pt-2 font-weight-bold">E-Mail</div>
					<div class="px-3">poststelle@lhw.mlu.sachsen-anhalt.de</div>
					
				</div>

        {/* <div class="shadow-box" >

					<div class="font-weight-bold">Datenschutzbeauftragter</div>
          <div class="px-1 pt-2 font-weight-bold">Name</div>
          <div class="px-3">Martin Hünecke</div>
					<div class="px-1 pt-2 font-weight-bold">Adresse</div>
          <div class="px-3">Otto-von-Guericke-Straße 05</div>
          <div class="px-3">39104 Magdeburg </div>

          <div class="px-1 pt-2 font-weight-bold">Telefon</div>
					<div class="px-3">+49 391 581 1255</div>
					
					<div class="px-1 pt-2 font-weight-bold">E-Mail</div>
					<div class="px-3">datenschutz@lhw.mlu.sachsen-anhalt.de</div>
					
					</div> */}
          <div class="px-1 pt-2 " style={{color:'#202121'}}>wichtiger Hinweis:</div>
				<div class="py-3  mx-4" style={{color:'#202121'}}>Für die von uns verlinkten Fremdangebote sind die jeweiligen Herausgeber verantwortlich. Das Kopieren und Verwenden unseres Foto- und Grafikangebotes ist nur mit Genehmigung zulässig. </div>
			
			
			</div>
		);
  }
}

export default ImpressumSide;



