import React, {Component} from 'react';
import { Line, Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import 'chartjs-plugin-zoom';



export default class LineDemo extends Component {

	getChartType =(typ, options, data)=>{
		var result = null;

		switch(typ){
			case 'line':{
				result = <div>        
							<Line ref="chart" options={options}  data={data} />
						</div>;

				break;
			}
			case 'bar':{
				result = <div>        
							<Bar ref="chart" options={options}  data={data} />
						</div>;
				break;
			}

		}

		return result;

	}
  render() {
  
    var annotations = [];
    var label_backgroundColor = "RGBa(0,0,0,0)";
    var label_position =  "left";
    var label_fontColor =  "RGB(52,58,64)";
    var label_fontSize = 10;
    var label_fontStyle =  "bold";
    var label_cornerRadius = 0;
    var label_xPadding = 0;
    var label_yPadding = 0;
    var label_xAdjust_mg =  5;
    var label_xAdjust_as1 =  5;
    var label_xAdjust_as2 =  40;
    var label_xAdjust_as3 =  5;
    var label_xAdjust_as4 =  40;
    var label_yAdjust =  0;
	var label_enabled =  true;
	



	//console.log(this.props.stages)

	var ymax = 0;
	var change = false;
	if(this.props.stages != null){
		this.props.stages.map(element=>{
			//console.log(element)
			
			change = !change;
			
			if(typeof element != 'undefined'){

				var indent = null;
				if(change){
					indent = label_xAdjust_mg
				}else{
					indent = label_xAdjust_as1
				}
				
			annotations.push({
				label: {
				  backgroundColor: label_backgroundColor,
				  position: label_position,
				  fontColor: label_fontColor,
				  fontSize: label_fontSize,
				  cornerRadius: label_cornerRadius,
				  xPadding:label_xPadding,
				  yPadding:label_yPadding,
				  xAdjust:indent,
				  yAdjust: label_yAdjust,
				  fontStyle: label_fontStyle,
				  enabled: label_enabled,
				  content:  element[0]
				  
				  },
				type: 'line',
				mode: 'horizontal',
				scaleID: 'y-axis-0',
				value: element[1],						
				borderColor: element[2],
				borderWidth: 2
			  });	

			  if(ymax < element[1]){
				  ymax = element[1]*1.05
			  }
			
			}
		});
	}
    
    
		annotations.push({
			type: 'line',
			mode: 'horizontal',
			scaleID: 'y-axis-0',
			value: 	ymax,					
			borderColor: label_backgroundColor,
			borderWidth: 0,
			borderDash: [1, 10],
		});

		
		
				 
	
    var data = {
    
      datasets: [
        {
          label: this.props.label,
          fill: true,
          lineTension: 0.1,
          backgroundColor: 'rgba(0,90,255,0.5)',
          borderColor: 'rgba(0,90,255,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(0,90,255,1)',
          pointBackgroundColor: 'rgba(0,90,255,1)',
          pointBorderWidth: 1,
          pointHoverRadius: 1,
          pointHoverBackgroundColor: 'rgba(0,90,255,0)',
          pointHoverBorderColor: 'rgba(0,90,255,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.props.data
        }
      ]
    };

    var options={	
      tooltips: {
		enabled:true,
		intersect: false,
        backgroundColor:'RGB(52,58,64)',
        titleFontColor:'rgba(255, 255, 255, 1)',
        bodyFontColor:'rgba(255, 255, 255, 1)',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'DD.MM'
          }
        },
      },		
			animation : false,
			responsive: true,
			legend:{
				display: false
			},
			scales: {
				xAxes: [{
					type: 'time',
					
					distribution: 'series',
					time: {
			unit: 'day',
			
           /*  parser: 'DD.MM', */
            tooltipFormat: 'DD.MM.YYYY HH:mm',
						 displayFormats: {
							day: 'DD.MM'
						} 
					},
					ticks:	{
						fontColor: 'RGBa(52,58,64,1)',
						beginAtZero:false,
						maxTicksLimit: 10
					},
					gridLines:{
						color: 'RGBa(52,58,64,0.5)'
					},
					scaleLabel: {
						fontColor: 'RGBa(52,58,64,1)',
						display: true,
						labelString: 'Datum'
					}
				}],
				yAxes: [{
					ticks:	{
						fontColor: 'RGBa(52,58,64,1)',
						beginAtZero:true,
						maxTicksLimit: 10
						
					},
					gridLines:{
						color: 'RGBa(52,58,64,0.5)'
					},
					scaleLabel: {
						fontColor: 'RGBa(52,58,64,1)',
						display: true,
						labelString: this.props.label
					}
				}]
			},
			elements: { point: { radius: 0 } },
			//plugins: {			     
			 annotation: {
				annotations: annotations,
				drawTime: "afterDraw" // (default)
				}, 
				/* zoom: {
					// Container for pan options
					pan: {
						// Boolean to enable panning
						enabled: true,
			
						// Panning directions. Remove the appropriate direction to disable
						// Eg. 'y' would only allow panning in the y direction
						mode: 'xy',
			
						rangeMin: {
							// Format of min pan range depends on scale type
							x: null,
							y: null
						},
						rangeMax: {
							// Format of max pan range depends on scale type
							x: null,
							y: null
						},
			
						// Function called while the user is panning
						onPan: function({chart}) { console.log(`I'm panning!!!`); },
						// Function called once panning is completed
						onPanComplete: function({chart}) { console.log(`I was panned!!!`); }
					},
			
					// Container for zoom options
					zoom: {
						// Boolean to enable zooming
						enabled: true,
			
						// Enable drag-to-zoom behavior
						drag: true,
			
						// Drag-to-zoom rectangle style can be customized
						// drag: {
						// 	 borderColor: 'rgba(225,225,225,0.3)'
						// 	 borderWidth: 5,
						// 	 backgroundColor: 'rgb(225,225,225)'
						// },
			
						// Zooming directions. Remove the appropriate direction to disable
						// Eg. 'y' would only allow zooming in the y direction
						mode: 'xy',
			
						rangeMin: {
							// Format of min zoom range depends on scale type
							x: null,
							y: null
						},
						rangeMax: {
							// Format of max zoom range depends on scale type
							x: null,
							y: null
						},
			
						// Speed of zoom via mouse wheel
						// (percentage of zoom on a wheel event)
						speed: 0.1,
			
						// Function called while the user is zooming
						onZoom: function({chart}) { console.log(`I'm zooming!!!`); },
						// Function called once zooming is completed
						onZoomComplete: function({chart}) { console.log(`I was zoomed!!!`); }
					}
				} */
			//}
    }
    

	/* var render = this.getChartType(this.props.typ, options, data); */
	var chart = null;

	switch(this.props.typ){
		case 'line':{
			chart =	<div>        
					<Line ref="chart" options={options}  data={data} height={300}/>
				</div>
			break;
		}
		case 'bar':{
			chart =	<div>        
					<Bar ref="chart" options={options}  data={data} />
				</div>
			break;
		}
	}
	
    return (
		chart
    );
  }

  componentDidMount() {
    //const { datasets } = this.refs.chart.chartInstance.data    
  }
}