import React, { Component } from 'react';
import ReactDOM from 'react-dom';









class ListeHochwassermeldePegel extends React.Component {
		
	componentDidMount(){		
		this.renderContent(this.props.list);
	}

	searchStation=(stations, searchstr)=> {
		return stations.filter(function(station) {
			return station.staname.toUpperCase().startsWith(searchstr.toUpperCase())       
		})    
	  }
	

	changeInput=()=>{
		
		var staname= document.getElementById('filterhwmp').value;
		var newStationList = this.searchStation(this.props.list, staname);
		this.renderContent(newStationList);
	}
	
		

	renderContent=(list)=>{

		ReactDOM.render(
			<div>
			{
				list.map((item, key)=> (
					
					<div 
						id={'id_' + item.stano} 
						class="station-short-info" 
						onClick={(e)=>{
							this.props.app.loadUIStationInformation(this.props.list,item.stano, this.props.listname, this.props.backbtn);
						}}
						>
						<div
							id={'a_station_' + item.stano} 
							site_no={item.sitename}						
							>
	
							{/* <img src={Functions.getIcon(item)}/> */}
							{/* <div class="my-auto " style={{backgroundColor: item.state.classification, height: 20, width:20}}></div> */}
							<div class="my-auto " >
								<img class="aicon" style={{backgroundColor: item.state.classification, borderRadius:(item.state.edge- 4), border: '2px solid #262626'}} src={'./icons/' +  item.state.img + '_icon_small.png'}/>
							</div>
							<div></div>
							<span class="font-weight-light">{item.stano}</span>
							<span class="font-weight-bold px-2">{item.staname}</span>
							<div></div>
							<span class="font-weight-light">Gewässer</span>
							<div></div>
							<span class="font-weight-bold px-2">{item.waterobj}</span>
							
						
	
					{
						(item.value != null)?	
							<div>
								<div></div>
								<span class="font-weight-light">{'W am ' + item.ts}</span>
								<div></div>				
								<span class="font-weight-bold px-2">{' ' + item.value + ' ' + item.unit}</span>
								<div></div>
								{
									(item.qvalue != null)?
									<div>
										<span class="font-weight-light">{'Q am ' + item.qts}</span>
										<div></div>
										<span class="font-weight-bold px-2">{' ' + item.qvalue + ' ' + item.qunit}</span>
									</div>
									:null
								}
								{
									(item.w_as != null && item.state.hwmp)?
										<div>			
											<div class="">Alarmstufen</div>
										
											{
												(item.w_as.mg !=  null)?
													<div>
														<span id={'mg_' + item.stano} class="px-2">MG:</span>
														<span class="px-4">{item.w_as.mg.value + ' ' + item.unit}</span>
													</div>
												:null
											}
	
											{
												(item.w_as.as1 !=  null)?
												<div>
														 <span id={'as1_' + item.stano} class="px-2">AS1:</span>
														 <span class="px-4">{item.w_as.as1.value + ' ' + item.unit}</span>
													</div>
												:null
											}
	
											{
												(item.w_as.as2 !=  null)?
												<div>
														<span id={'as2_' + item.stano} class="px-2">AS2:</span>
														<span class="px-4">{item.w_as.as2.value + ' ' + item.unit}</span>
													</div>
												:null
											}	
	
											{
												(item.w_as.as3 !=  null)?
												<div>
														<span id={'as3_' + item.stano} class="px-2">AS3:</span>
														<span class="px-4">{item.w_as.as3.value + ' ' + item.unit}</span>
													</div>
												:null
											}	
	
											{
												(item.w_as.as4 !=  null)?
													<div>
														<span id={'as4_' + item.stano} class="px-2">AS4:</span>
														<span class="px-4">{item.w_as.as4.value + ' ' + item.unit}</span>
													</div>
												:null
											}
	
										</div>	
									:null
								}
							</div>					
						:
						<div>
							<span class="font-weight-light">Derzeit kein Daten vorhanden</span>
						</div>
						
					}
				</div>
			</div>			
						
				))		
					
			}
			</div>,
			document.getElementById('listhwmp')
		)
	}
	
	
    render() {   
	
	return (
		<div class="text-dark text-center">
		<div class="">			
			<div class="navbar-brand header-container ">
				
				
				<div class="my-auto " onClick={
					this.props.backbtn?
					(e)=>this.props.app.loadUIAllStations()
					:
					(e)=>this.props.app.loadStartside()
				}>
					<img class="" src="./icons/arrow_left.png" style={{width:'35px'}}/>
				</div>

				
				<div class="header-text" >{this.props.listname}</div>
					
				
				<div class="my-auto" onClick={(e)=>{this.props.app.showModalMenue()}}>
					<img src="./icons/menue_points.png" class="" style={{width:40}}/>	 
					</div>
			</div>

			<div class="search-content">				
				
				<img class="p-2 " src="./icons/search-dark.png" style={{height:'45px'}}/>
				<input 
					id="filterhwmp" 			 
					name="search" 
					type="text" 
					placeholder="Pegelname"
					onChange={(e)=>this.changeInput()} 
					/>
			</div>


		</div>
		<div id="listhwmp" class="text-left pb-4 mx-4">
		

		</div>
		</div>			
    );
  }
}

export default ListeHochwassermeldePegel;



