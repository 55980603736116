import React, { Component } from 'react';


const shortcuts =[
	{
		'long':'Landesbetrieb für Hochwasservorherschutz und Wasserwirschaft',
		'short':'LHW'
	},
	{
		'long':'Hochwasser-Vorhersage-Zentrale',
		'short':'HVZ'
	},
	{
		'long':'Sachsen-Anhalt',
		'short':'ST'
	},
	{
		'long':'Hochwasser',
		'short':'HW'
	},
	{
		'long':'Hochwassermeldeordnung',
		'short':'HWMO'
	},
	{
		'long':'Meldegrenze',
		'short':'MG'
	},
	{
		'long':'Alarmstufe 1',
		'short':'AS1'
	},
	{
		'long':'Alarmstufe 2',
		'short':'AS2'
	},
	{
		'long':'Alarmstufe 3',
		'short':'AS3'
	},
	{
		'long':'Alarmstufe 4',
		'short':'AS4'
	},

]





class Shortcutside extends React.Component {
		
	componentDidMount(){
	
	}
	
    render() { 	
		return (
		<div class="text-white text-center">
			<div class="  navbar-brand header-container">
				
				
				<div class="my-auto " onClick={					
					(e)=>this.props.app.loadStartside()
				}>
					<img class="" src="./icons/arrow_left.png" style={{width:'35px'}}/>
				</div>

				
				<div class="header-text">Abkürzungen</div>
					
				
				<div class="my-auto" onClick={(e)=>{this.props.app.showModalMenue()}}>
					<img src="./icons/menue_points.png" class="" style={{width:40}}/>	 
					</div>
			</div>
		
			{/* <div>
				<div id="" class="h5">Abkürzungen</div>
			</div> */}
			
			<div  >
				{
					shortcuts.map((shortcut)=>(
						<div class="menue-btn">
							<div class="font-weight-bold">{shortcut.short}</div>
							<div>{shortcut.long}</div>
						</div>

					))
				}
			</div>	
			</div>			
		);
  }
}

export default Shortcutside;



