import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PDFView from './PDFView';

class HwiSide extends React.Component {
		
	componentDidMount(){}    
	
    render() { 	
        var subStr = 'HW_Warnung_';
		return (
			<div>
                <div class="navbar-brand header-container">
               
               <div class="my-auto " onClick={					
               (e)=>this.props.app.loadStartside()
               }>
               <img class="" src="./icons/arrow_left.png" style={{width:'35px'}}/>
               </div>

               
               <div class="header-text">HW-Warnungen</div>
               
               
               <div class="my-auto" onClick={(e)=>{this.props.app.showModalMenue()}}>
					<img src="./icons/menue_points.png" class="" style={{width:40}}/>	 
					</div>
             </div>	

                {/* <div class="p-4 text-white text-center h4">HW-Warnungen</div> */}
                {
                    this.props.catchments.map(catchment=>(
                        <div>
                        {
                            this.props.filejson.map(file=>{
                                try {
                                    var json = JSON.parse(file);
                                    if(catchment === json.catchment) {
                                        return (
                                            <div id="hwwcontent" class=" shadow-boxp-2 rounded flex-grow-1 text-left  m-4 text-dark " 
                                                onClick={(e)=>{
                                                    ReactDOM.render(
                                                        <PDFView app = {this.props.app} mainpath={null} document={json.file} title={'HW-Warnungen'} back={(e)=>this.props.app.loadHwwSide(this.props.catchments, this.props.filejson)} date={null}/>, document.getElementById('content_')
                                                    );
                                                
                                            }} >
                                                <div class="mb-1">{'Flussgebiet ' + catchment} </div>
                                                <div class="menue-btn" >{json.filename.replace(subStr, '')}
                                                </div>                        
                                            </div>
                                        )
                                    } 
                                }catch(e){
                                    if (e instanceof SyntaxError) {
                                      return <div class="px-3 text-dark">JsonSyntaxError</div>
                                    } else {
                                       return <div class="px-3 text-dark">Error</div>
                                    }

                                }
                            })
                        }
                       </div>
                    ))
                }
            </div>
		);
  }
}

export default HwiSide;



