import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import Header from './header';
import Alarm from './alarm'
import Allgemein from './allgemein'


class Main extends React.Component {
	componentDidMount() { 
		
		this.props.app.loadStartside();
		
  }
		
	render() {   
		return (
			<div class="main-view">

				


				{/* <div id="header" class="fixed-top  mx-auto px-3 bg-secondary" style={{maxWidth:960}}>
					<Header app={this.props.app} ltzakt= {this.props.app.state.ltzAkt} />
				</div> */}	

				{/* <div id="overview" class="" style={{position:"relative", top:10}}></div> */}
				{/* <div id="topnav_" class="" style={{position:"relative", top:10}}></div> */}
				<div id="content_" class="main-content"></div>
				
				{/* <div id="footer"class="fixed-bottom mx-auto px-3" style={{maxWidth:960}}>
					<Footer/>	
				</div> */}	


				<div class="modal fade" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
				<div class="modal-dialog modal-dialog-scrollable modal-dialog-centered " role="document">
					<div class="modal-content" style={{backgroundColor:'rgba(255,255,255,1)'}}>
					<div class="modal-header ">
						
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>
					{/* <div class="modal-body">
						<Alarm/> 
						<Allgemein/> 
					</div> */}
					
					</div>
				</div>
				</div>

			</div>			
		);
	}
}
			
export default Main;