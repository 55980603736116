import React, { Component } from 'react';
import Functions from './functions';			

			
class NavStation extends React.Component {
	

	loadNextStationInfo=(index)=>{
		
		var newindex = 0;
		
		if(index < 0 ){			
			newindex = this.props.list.length - 1;
		}else{
			if(index > (this.props.list.length - 1)){
				newindex = 0;
			}else{
				newindex = index;
			}
		}		

		//console.log(this.props.backbtn)
		this.props.app.loadUIStationInformation(this.props.list, this.props.list[newindex].stano, this.props.listname, this.props.backbtn);
	}	
	
    render() {  
		return (
			<div class="px-3">
			<div class="navbar-brand   rounded text-dark   d-flex justify-content-around mb-4" >
				{/* <div id="stanav" class="text-center p-2 d-flex justify-content-center mw-100" > */}
				{/* <div id="back2listButton" class="navbar-brand rounded border-white mx-auto bg-dark" style={{width: 45, height: 45, borderRadius:10}}
					onClick={(e)=>{											
						this.props.app.loadUIHWMP(this.props.list, this.props.listname, this.props.backbtn);						
							
					}}
				>
					<img class="img-fluid" src="./icons/chevron-left.png"/>
				</div> */}


				<div id="listUpButton" class="navbar-brand rounded mx-auto shadow-box shadow-box" style={{backgroundColor:'rgba(255,255,255,0.5)'}}
				onClick={(e)=>{										
					this.loadNextStationInfo(this.props.index-1);						
				}}
				>
					<img class="img-fluid" src="./icons/chevron-up-dark.png" style={{width:40}}/>
				</div>


				<div id="listDownButton" class="navbar-brand rounded mx-auto shadow-box" style={{backgroundColor:'rgba(255,255,255,0.5)'}}
				onClick={(e)=>{											
					this.loadNextStationInfo(this.props.index+1);	
				}}
				>
					<img class="img-fluid" src="./icons/chevron-down-dark.png" style={{width:40}}/>
				</div>
			</div>
			</div>
		);
  }
}

export default NavStation;					