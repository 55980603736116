import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import Map from 'ol/Map';
import View from 'ol/View';

import _style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import RegularShape from 'ol/style/RegularShape';
import Icon from 'ol/style/Icon';
import SourceVector from 'ol/source/Vector';
import LayerVector from 'ol/layer/Vector';
import Overlay from 'ol/Overlay';
import GeoJson from 'ol/format/GeoJSON';
//import Control from 'ol/control/Control';
import {defaults as defaultControls, Control} from 'ol/control';
import {defaults as defaultInteraction, Interaction} from 'ol/interaction';

//import Interaction from 'ol/interaction/Interaction';
import Point from 'ol/geom/Point';
import MultiPoint from 'ol/geom/MultiPoint';
import Feature from 'ol/Feature';



import StationInformations from './stationsinfo';
import Functions from './functions';

import bundesland from './layers/bundesland.json'
import bundeslaender from './layers/bundeslaender.json'
import elbestrom from './layers/elbestrom.json'
import hauptfluesse from './layers/hauptfluesse.json'
import nebenfluesse from './layers/nebenfluesse.json'
import tsb from './layers/talsperren.json'
import staedteliste from './layers/staedteliste.json'



const e = React.createElement;


var map
var station_small_name = null;
var geojsonObject = null;

var span = '<span style="font-size: 16px; font-weight: normal;">';
var span_klein = '<span style="font-size: 12px; font-weight: normal;">';

var hwwarndata;
var hwinfodata;




						
					





var stationSource_small = new SourceVector({	});
var stationSource_small_name = new SourceVector({	});
var stationSource_big_name = new SourceVector({	});

var stationLayer_smallIcons = new LayerVector({
	zIndex: 10,
	name: 'stationlayersmall'		
});

var stationLayer_smallIcons_name = new LayerVector({
		zIndex: 10,
		name: 'stationlayersmallname'		
    });
	
var stationLayer_bigIcons_name = new LayerVector({
		zIndex: 10,
		name: 'stationlayerbigname'		
    });

///////////////Popup///////////////////////////////////////

var container = document.getElementById('popup');
var content = document.getElementById('popup-content');
/* 
$('#popup-content').html('<div class="text-center" style="background-color: #252525; padding: 3px; border-radius: 10px">'
						+'<span id="popup_staname" class="font-weight-normal"></span>' 
						+ '<br>'
						+'<span id="popup_wto" class="font-weight-normal"></span>'
						+ '<br>'
						+ 'Wasserstand'
						+ '<br>'
						+ '<span id="popup_ws_ts" class="font-weight-normal" ></span>'
						+ '<br>'
						+ '<span id="popup_ws_value" class="font-weight-normal"></span>'
						+ '<br>' 
						+ '<div class="text-center"> <button id ="popup_button" type="button" class="btn btn-block p-1 mt-3 mb-3 rounded font-weight-normal" style="background-color: #000; color: #fff;width: 50%; margin-left:auto; margin-right:auto;" >' 
						+  'Mehr Info</button> </div>'
						+ '</div>')
 */
/*
var closer = document.getElementById('popup-closer');

closer.onclick = function() {
	container.style.display = 'none';
	closer.blur();
	return false;
};
*/
var overlay = new Overlay({
	element: container,
	offset: [-25,-10]
});	


function showStaInfo(listnr){
	//overlay.setPosition(undefined);
	//closer.blur();
	//showStationInfomations(listHwmp, listnr, 'Alle Hochwassermeldepegel', true);
	ReactDOM.render(
		<StationInformations	 list = {Functions.getListHwmp()} index={listnr} listname='Alle HW-Meldepegel' png='Functions.getIcon(item)'/>, document.getElementById('main')
	);
}	





var default_center = [ 717000.0, 5720000.0 ];
	


function setZoom(zoom){
	map.getView().setZoom(zoom);
}


function getTText(text, offsetY, txtcolor, bgcolor, size, weight){
	return new Text({
		textAlign: '',//align == '' ? undefined : align,
		textBaseline: 'middle',//baseline,
		font: weight + ' ' + size + ' ' + 'Calibri',//font,
		text: text,
		padding:[5,5,3,5],
		backgroundFill: new Fill({color: bgcolor}),
		fill: new Fill({color: txtcolor}),
		stroke: '',//new Stroke({color: outlineColor, width: outlineWidth}),
		offsetX: '0',//offsetX,
		offsetY: offsetY,//offsetY,
		placement: '',//placement,
		maxAngle: '',//maxAngle,
		overflow: '',//overflow,
		rotation: ''//rotation
	});
}

var iconFeatureNS = new Feature({
						icon: true,
						name:	'Niedersachsen',
						labelPoint: new Point([ 605000.00, 5780000.00 ]),
						style: new _style({
							text: getTText('Niedersachsen', (-15), '#393E41', 'rgb(0,0,0,0)', '14px', 'normal')
						})
					});
iconFeatureNS.setGeometryName('labelPoint');

var iconFeatureBRB = new Feature({
						icon: true,
						name:	'Brandenburg',
						labelPoint: new Point([ 755000.00, 5780000.00  ]),
						style: new _style({
							text: getTText('Brandenburg',(-15), '#393E41', 'rgb(0,0,0,0)', '14px', 'normal')
						})
					});
iconFeatureBRB.setGeometryName('labelPoint');

var iconFeatureS = new Feature({
						icon: true,
						name: 'Sachsen',
						labelPoint: new Point([ 775000.00, 5630000.00 ]),
						style: new _style({
							text: getTText('Sachsen', (-15), 'black', 'rgba(0,0,0,0)', '14px', 'normal')
						})
					});
iconFeatureS.setGeometryName('labelPoint');

var iconFeatureTH = new Feature({
						icon: true,
						name: 'Thüringen',		
						labelPoint:	new Point([ 620000.00, 5630000.00 ]),
						style: new _style({
							text: getTText('Thüringen',(-15), 'black', 'rgba(0,0,0,0)', '14px', 'normal')
						})
					});
iconFeatureTH.setGeometryName('labelPoint');
	
var layer_icon = 	new LayerVector({
						zIndex: 1,
						style: function(feature) {
							return feature.get('style');
						},
						source: new SourceVector({features: [iconFeatureNS, iconFeatureBRB, iconFeatureS, iconFeatureTH]})
					});


	
var geojsonObject = {
        'type': 'FeatureCollection',
        'crs': {
			'type': 'name',
			'properties': {
				'name': 'EPSG:3857'
			}
		},
        'features': []
      };

	  

function getStationFeature(station, isClickable){
	//name, waterobj, geo, ts, value, as, iconsize, index){
	//station.staname, station.waterobj, station.geo, station.ts, station.value, station.state.as, 'small', key	
	let color = station.state.classification;
	let zIndex = 0;
	let img =station.state.img;
	let edge = station.state.edge;
	
	if(station.state.as != null){
		switch(station.state.as){
			case 'mg':{				
				zIndex = 1;
				break;
			}
			case 'as1':{
				zIndex = 2;
				break;
			}
			case 'as2':{
				zIndex = 3;
				break;
			}
			case 'as3':{
				zIndex = 4;
				break;
			}
			case 'as4':{
				zIndex = 5;
				break;
			}
			case 'ok':{
				zIndex = 0;
				break;
			}
			case 'old': {
				zIndex = 0;
				break;
			}
			case 'or': {
				zIndex = 0;
				break;
			}
			default:{
				zIndex = 0;
			}
			
		}
	}else{
		zIndex = 0;
	}
		
	
	
	  
	/* var stroke = new Stroke({color: '#ccc', width: 1});
    var fill = new Fill({color: color}); 
	  
	var iconStyle_small = new _style({
		image: new RegularShape({    
			opacity: 1.0,	
			radius: 7,					
			points:4,
			angle: Math.PI/4,
			stroke: stroke,
			fill: fill
		})
	});  */
	
	
	
	var station = new Feature({
				img: img,
				edge: edge,
				zIndex: zIndex,
				color: color,
				isStation: true,
				isClickable: isClickable,	
				station: station,				
				geometry: new Point([station.geo[0], station.geo[1]]),		
			  });		
	
	//station.setStyle(iconStyle_small);

	
			
	/*
	
	var x = station.geo[0];
	var y	= station.geo[1];
	
	 geojsonObject_name.features.push({
		'properties': { 			
			'_click': false,
			'station': true,
			'listindex': index,
			'staname': station.staname,
			'wts':	station.ts,	
			'wvalue': station.value,
			'color': color,
			'point' :[x,y]
		},
		'type': 'Feature',
        'geometry': {
			'type': 'Polygon',
			'coordinates': [[[(x-1050), (y+1050)], [(x+1050), (y+1050)], [(x+1050), (y-1050)], [(x-1050), (y-1050)]]]
        }
    });
	
	geojsonObject.features.push({
		'properties': { 
			'_name': 'test0',
			'_click': true,
			'station': true,
			'listindex': index,
			'staname': name,
			'waterobj': waterobj,
			'wts':	ts,	
			'wvalue': value,
			'color': color,
			'point' :[x,y]
		},
		'type': 'Feature',
        'geometry': {
			'type': 'Polygon',
			'coordinates': [[[(x-1050), (y+1050)], [(x+1050), (y+1050)], [(x+1050), (y-1050)], [(x-1050), (y-1050)]]]
        }
    }); */

	return station;
}










var btn_style = 'div_shadow navbar-brand d-flex bg-secondary text-white p-2 rounded-0';



class MapView extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
		zoom:7
		}
	}
	componentDidMount() {
		//let debug = "" + document.getElementById('content_').offsetWidth + ','  +  document.getElementById('content_').offsetHeight;
		
		//////console.log('map ready');
		let zoom = null;
		if(document.getElementById('content_').offsetWidth < document.getElementById('content_').offsetHeight){
			zoom = 7.8;
			if(document.getElementById('content_').offsetWidth >= 600){
				zoom = 9;
			}
			if(document.getElementById('content_').offsetWidth <= 360){
				//alert(debug)
				zoom = 7.7;
			}
		}else{
			zoom = 6.8;
			if(document.getElementById('content_').offsetHeight > 600){
				zoom = 8.3;
			}
			if(document.getElementById('content_').offsetHeight <= 600){
				
				zoom = 7;
			}

		}
		this.setState({
			zoom: zoom
		},()=>{
			this.loadMap();//Fkt aus maplayer
			this.setMapContent();
		})
	}
	
	test=()=>{
		console.log('click');
	}
	loadMap=()=>{
		var thisClass = this;
		map = new Map({
			layers: [],
			target: 'map',		
			controls : defaultControls({
				attribution : false,
				zoom : false,
				overview: false,
				rotate: false
			}),		
			view: new View({
				center: default_center,				
				extent: [600000.0, 5645000.0, 850000.0, 6800000.0], 
				zoom: this.state.zoom,
				minZoom: 7,
				maxZoom: 13,
			}),
			restrictedExtent: false,
			interactions: defaultInteraction({
				dragPan: true,
				altShiftDragRotate:false, 
				pinchRotate:false
			})		
		});	
	
	
		map.addOverlay(overlay);
	
	
		map.getView().on('propertychange', function(e) {
			//container.style.display = 'none';
		
			if(map.getView().getZoom() >= 7 && map.getView().getZoom() < 10){		
				
				stationLayer_smallIcons.setVisible(true);
				stationLayer_smallIcons_name.setVisible(false);
				stationLayer_bigIcons_name.setVisible(false);
			}
			
			
			if(map.getView().getZoom() >= 10 && map.getView().getZoom() < 12){		
				//stationLayer_bigIcons.setVisible(false);
				stationLayer_smallIcons.setVisible(false);
				stationLayer_smallIcons_name.setVisible(true);
				stationLayer_bigIcons_name.setVisible(false);
			}

			if(map.getView().getZoom() >= 12){		
				stationLayer_smallIcons.setVisible(false);
				stationLayer_smallIcons_name.setVisible(false);		
				stationLayer_bigIcons_name.setVisible(true);
			}
		});		
			

		var offsX = 0;
		var offsY =	2100;
	
	
	
		map.on('click', function(evt) {
			//console.log('click0');
			var feature = map.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {		
				return feature;
			});
			if(feature != null){
				//console.log('click1');
				if(feature.get('isClickable') && feature.get('station') != null){
					//console.log('click2');
					var coordinates = feature.getGeometry().getCoordinates();
					map.getView().setCenter([coordinates[0] + offsX ,coordinates[1] + offsY])
					overlay.setPosition([coordinates[0] ,coordinates[1] + 4380]);
					console.log(feature.get('station'))
					thisClass.props.app.loadUIStationInformation(thisClass.props.app.state.listHwmp, feature.get('station').stano, 'Alle HW-Meldepegel', false);

					//this.props.app.loadUIStationInformation(thisClass.props.app.state.listHwmp, feature.get('station').stano, 'Alle Hochwassermeldepegel', true)
					/* ReactDOM.render(
						<div  class="text-center" style={{backgroundColor: '#252525', padding: 3}}>
							
						<div class="d-flex flex-wrap text-center justify-content-between ">
							<div class="mr-2">	
								<div class="d-flex flex-wrap">
									<div id="popup_staname" class="font-weight-normal">{feature.get('station').staname}</div>
									<div id="popup_wto" class="font-weight-normal">{'(' + feature.get('station').waterobj + ')'}</div>						
								</div>

								<div class="d-flex flex-nowrap">
									{
										(feature.get('station').value != null)?
										<div class="d-flex">
											<div>W:</div>
											<div id="" class="pl-2 font-weight-normal">{feature.get('station').value + ' ' + feature.get('station').unit}</div>							
											<div id="" class="pl-1 font-weight-normal" >{'(' +feature.get('station').ts + ')'}</div>
										</div>
										:<div>Derzeit keine Daten vorhanden </div>

									}
								</div>	
							</div>	
							<div></div>

							
								<div 
									class="btn  font-weight-normal text-white " 
									style={{backgroundColor: 'black'}} 
									onClick={()=>{thisClass.props.app.loadUIStationInformation(thisClass.props.app.state.listHwmp, feature.get('station').stano, 'Alle Hochwassermeldepegel', false)}}>
									Mehr Info
								</div>
							</div>
						</div> 	
						, 
						document.getElementById('popup_')) */
					
					/* $('#popup_staname').html(feature.get('station').staname);
					$('#popup_wto').html('(' + feature.get('station').waterobj + ')');
					$('#popup_ws_ts').html(feature.get('station').wts);
					$('#popup_ws_value').html(feature.get('station').value + ' '+ feature.get('station').unit);
					$('#popup_button').on('click', function(){
						console.log(feature.get('listindex'))
						thisClass.props.app.loadUIStationInformation(thisClass.props.app.state.listHwmp, feature.get('listindex'), 'Alle Hochwassermeldepegel');				
					});				
					*/
					//container.style.display = 'block';	 
					
					
				}else{
					ReactDOM.unmountComponentAtNode(document.getElementById('popup_'));
				}
			}
		});		

		
	}


	setZoomUp=()=>{
		map.getView().setZoom(map.getView().getZoom() + 1);
	}
	
	
	
	
	
	
	
	setZoomCenter=()=>{
		map.getView().setCenter(default_center);
	map.getView().setZoom(this.state.zoom);
		
	}
	
 setZoomDown= ()=>{
	map.getView().setZoom(map.getView().getZoom() - 1);
}


	
	setMapContent=()=>{

		//console.log(this.props.app.state.listHwmp)

		//container.style.display = 'none';	
		
		this.setVectorLayerFromJson();	
		
		map.addLayer(layer_icon);
	  
	
		this.props.app.state.listHwmp.map((station, key)=> {
			stationSource_small.addFeature(getStationFeature(station, false));
			stationSource_small_name.addFeature(getStationFeature(station, false));
			stationSource_big_name.addFeature(getStationFeature(station, true));


			/* if(!station.state.old){
				stationSource_small.addFeature(getStationFeature(station, 'small', key));
			}else{
				stationSource_small.addFeature(getStationFeature(station, 'small', key));
			} */
		});	 
	
		var stroke = new Stroke({color: '#ccc', width: 0});
		var fill = new Fill({color: '#ccc'}); 

		/* var style_smallIcon = (feature)=>{			
			return	[ 	
				new _style({					
					image: new RegularShape({  
						
						opacity: 1.0,	
						radius: 7,					
						points: 4,
						angle: Math.PI/4,
						stroke: new Stroke({color: '#393E41', width: 1}),
						fill: new Fill({color: feature.get('color')})
					}),
					zIndex:  feature.get('zIndex'),				  
				})
			]
		}; */
		let style_smallIcon = (feature)=>{			
			
			return	[ 
				new _style({					
					image: new RegularShape({    
						opacity: 1.0,	
						radius: 7,					
						points:  feature.get('edge'),
						angle: Math.PI/4,
						stroke: new Stroke({color: '#393E41', width: 1}),
						fill: new Fill({color: feature.get('color')})
					})					  
				}),
		new _style({
			
			image: 	new Icon(({
						color: 'white',
						anchor: [0.5, 0.5],
						anchorXUnits: 'fraction',
						anchorYUnits: 'fraction',
						scale: 0.5,
						src: './icons/' + feature.get('img') +'_icon_small.png'
					}))
			
		})]
	};
	
		/* var style_smallIcon_name = (feature)=>{
			var color = feature.get('color');
			var name = feature.get('station').staname;
			console.log(color)
			return	[ 	
				new _style({
					text: getTText(name, (-10), 'black', 'rgba(0,0,0, 0)'),
					image: new RegularShape({    
						opacity: 1.0,	
						radius: 7,					
						points: 4,
						angle: Math.PI/4,
						stroke: new Stroke({color: '#393E41', width: 1}),
						fill: new Fill({color: color})
					})					  
				})
			]
		}; */

		let style_smallIcon_name = (feature)=>{			
			var name = feature.get('station').staname;
			return	[ 
				
				new _style({					
					image: new RegularShape({    
						opacity: 1.0,	
						radius: 10,					
						points:  feature.get('edge'),
						angle: Math.PI/4,
						stroke: new Stroke({color: '#393E41', width: 1}),
						fill: new Fill({color: feature.get('color')})
					})					  
				}),
		new _style({
			text: getTText(name, (-15), 'black', 'rgba(0,0,0, 0)', '14px', 'normal'),
			image: 	new Icon(({
						color: 'white',
						anchor: [0.5, 0.5],
						anchorXUnits: 'fraction',
						anchorYUnits: 'fraction',
						scale: 0.7,
						src: './icons/' + feature.get('img') +'_icon_small.png'
					}))
			
		})]
	};
	 
		/* var style_bigIcon_name = (feature)=>{
			var color = feature.get('color');
			var name = feature.get('station').staname;
			var value = 'Keine Werte';
            var ts = '';
			if(feature.get('station').value != null){
				value = feature.get('station').value + feature.get('station').unit;
				ts = ' (' +  feature.get('station').ts + ')';
                            
			};
			console.log(color)
			return	[ 
				new _style({
					image: new RegularShape({    
						opacity: 0.1,	
						radius:30,					
						points: 4,
						angle: Math.PI/4,
						stroke: new Stroke({color: '#393E41', width: 1}),
						fill: new Fill({color: color})
					}),
					//geometry: new Point(coordinates)
				}),
				new _style({
					text: getTText(name, (-30), 'dark', 'rgba(255,255,255, 0)'),
					image: 	new Icon(({
								color: color,
								anchor: [0.5, 0.5],
								anchorXUnits: 'fraction',
								anchorYUnits: 'fraction',
								src: './icons/messstelle.png'
							}))
					
				}),
				new _style({
					text: getTText(value, (40), 'dark', 'rgb(255,255,255, 0)'),
                }),
                new _style({
					text: getTText(ts, (55), 'dark', 'rgb(255,255,255, 0)'),
				}),
				
			]
		}; */

		var style_bigIcon_name = (feature)=>{
			var color = feature.get('color');
			var name = feature.get('station').staname;
			var value = 'Keine Werte';
            var ts = '';
			if(feature.get('station').value != null){
				value = feature.get('station').value + feature.get('station').unit;
				ts = ' (' +  feature.get('station').ts + ')';
                            
			};
			console.log(color)
			return	[ 
				new _style({					
					image: new RegularShape({    
						opacity: 1.0,	
						radius: 15,					
						points:  feature.get('edge'),
						angle: Math.PI/4,
						stroke: new Stroke({color: '#393E41', width: 1}),
						fill: new Fill({color: feature.get('color')})
					})					  
				}),
				new _style({
					text: getTText(name, (-20), 'dark', 'rgba(255,255,255, 0)', '14px', 'normal'),
					image: 	new Icon(({
								color: 'white',
								anchor: [0.5, 0.5],
								anchorXUnits: 'fraction',
								anchorYUnits: 'fraction',
								scale: 1.0,
							src: './icons/' + feature.get('img') +'_icon_small.png'
							}))
					
				}),
				new _style({
					text: getTText(value, (20), 'dark', 'rgb(255,255,255, 0)', '14px', 'normal'),
                }),
                new _style({
					text: getTText(ts, (35), 'dark', 'rgb(255,255,255, 0)', '14px', 'normal'),
				}),
				
			]
		}; 
		
		
		/*(feature)=>{
			
			var color = feature.get('color');
			var name = feature.get('station').staname;
					
			return	[ 	
				new _style({
					text: getTText(name, (-5), '#252525'),
					image: new RegularShape({    
						opacity: 1.0,	
						radius: 7,					
						points: 4,
						angle: Math.PI/4,
						stroke: new Stroke({color: '#ccc', width: 1}),
						fill: new Fill({color: color})
					})					  
				})
				
				 new _style({
					stroke: new Stroke({
						color: 'rgba(255, 250, 250, 1.0)',
						width: 5
					}),
					fill: new Fill({
							color: 'rgba(255, 250, 250, 1.0)'
						})
				}),
				new _style({
					image: new RegularShape({    
						opacity: 1.0,	
						radius: 2,					
						points: 4,
						angle: Math.PI/4,
						stroke: new Stroke({color: '#ccc', width: 1}),
						fill: new Fill({color: color})
					}),
					geometry: new Point(coordinates)
				}),

				 
			]
		};
*/

		stationLayer_smallIcons.setSource(stationSource_small);	
		stationLayer_smallIcons.setStyle(style_smallIcon);
		stationLayer_smallIcons.setVisible(true); 
		
		
		stationLayer_smallIcons_name.setSource(stationSource_small_name);	
		stationLayer_smallIcons_name.setStyle(style_smallIcon_name);
		stationLayer_smallIcons_name.setVisible(false);
	
	
		stationLayer_bigIcons_name.setSource(stationSource_big_name);
		stationLayer_bigIcons_name.setStyle(style_bigIcon_name);
		stationLayer_bigIcons_name.setVisible(false);
				
		
		map.addLayer(stationLayer_smallIcons);
		map.addLayer(stationLayer_smallIcons_name);				
		map.addLayer(stationLayer_bigIcons_name);
	
		
	}

				
				
	setVectorLayerFromJson=()=>{

/////////////Bundesland//////////////////////////////////////////////////
		var vectorBundesland = new SourceVector({
			features: (
				new GeoJson({
					defaultDataProjection: "EPSG:25832"
				})
			).readFeatures(bundesland)})

		var styleBundesland = 	new _style({
			stroke: new Stroke({
				color: '#fff',
				
					width: 5
				
			}),
			fill: new Fill({
				color: '#A4B8C4'
			})
		});

		var vectorLayerBundesland = new LayerVector({
			zIndex: -1,
			source: vectorBundesland,
			style: styleBundesland
		});
			
		map.addLayer(vectorLayerBundesland);

////////////////////////////////////////////////////////////////////////////////////
/////////////Bundeslaender//////////////////////////////////////////////////
var vectorBundeslaender = new SourceVector({
	features: (
		new GeoJson({
			defaultDataProjection: "EPSG:25832"
		})
	).readFeatures(bundeslaender)
})

var styleBundeslaender =	new _style({
	stroke: new Stroke({
		color: '#fff',
				
					width: 1
	}),
	fill: new Fill({
		color: '#C8D3D5'
	})
});	
var vectorLayerBundeslaender = new LayerVector({
	zIndex: -1,
	source: vectorBundeslaender,
	style: styleBundeslaender
});
	
map.addLayer(vectorLayerBundeslaender);

////////////////////////////////////////////////////////////////////////////////////
/////////////Elbestrom//////////////////////////////////////////////////
var vectorElbestrom = new SourceVector({
	features: (
		new GeoJson({
			defaultDataProjection: "EPSG:25832"
		})
	).readFeatures(elbestrom)
})

var styleElbestrom = 	new _style({
	stroke: new Stroke({
		color: 'rgba(0, 90, 200, 1)',
		width: 2
	})
});
var vectorLayerElbestrom = new LayerVector({
	zIndex: -1,
	source: vectorElbestrom,
	style: styleElbestrom
});
	
map.addLayer(vectorLayerElbestrom);

////////////////////////////////////////////////////////////////////////////////////

/////////////Hauptfluesse//////////////////////////////////////////////////
var vectorHauptfluesse = new SourceVector({
	features: (
		new GeoJson({
			defaultDataProjection: "EPSG:25832"
		})
	).readFeatures(hauptfluesse)
})

var styleHauptfluesse = 	new _style({
	stroke: new Stroke({
		color: 'rgba(0, 115, 200, 1)',
		width: 2
	})
});
var vectorLayerHauptfluesse = new LayerVector({
	zIndex: -1,
	source: vectorHauptfluesse,
	style: styleHauptfluesse
});
	
map.addLayer(vectorLayerHauptfluesse);

////////////////////////////////////////////////////////////////////////////////////

/////////////Nebenfluesse//////////////////////////////////////////////////
var vectorNebenfluesse = new SourceVector({
	features: (
		new GeoJson({
			defaultDataProjection: "EPSG:25832"
		})
	).readFeatures(nebenfluesse)
})

var styleNebenfluesse = 	new _style({
	stroke: new Stroke({
		color: 'rgba(0, 115, 200, 1)',
		width: 1
	})
});
var vectorLayerNebenfluesse = new LayerVector({
	zIndex: -1,
	source: vectorNebenfluesse,
	style: styleNebenfluesse
});
	
map.addLayer(vectorLayerNebenfluesse);

////////////////////////////////////////////////////////////////////////////////////

/////////////tsb//////////////////////////////////////////////////
var vectorTSB = new SourceVector({
	features: (
		new GeoJson({
			defaultDataProjection: "EPSG:25832"
		})
	).readFeatures(tsb)
})

var styleTSB  = 	new _style({
	stroke: new Stroke({
		color: 'rgba(0, 115, 200, 1)',
				
					width: 1
	}),
	fill: new Fill({
		color: 'rgba(0, 115, 200, 1)',
	})
});
var vectorLayerTSB  = new LayerVector({
	zIndex: -1,
	source: vectorTSB ,
	style: styleTSB 
});
	
map.addLayer(vectorLayerTSB );

////////////////////////////////////////////////////////////////////////////////////

/////////////Staedte/////////////////////////////////////////////////
var vectorStaedte = new SourceVector({
	features: (
		new GeoJson({
			defaultDataProjection: "EPSG:25832"
		})
	).readFeatures(staedteliste)
})

var styleStaedte = 	 (feature)=>{			
	return	 	new _style({	
		text: getTText(feature.get("name"), (-10), '#888', 'RGBa(255,255,255,0)', '12px', ''),				
		image: new RegularShape({    

			opacity: 1.0,	
			radius:3,					
			points: 20,
			angle: Math.PI/4,
			stroke: new Stroke({color: '#888', width: 1}),
			fill: new Fill({color: '#888'})
		}),
		zIndex: 100				  
	}) 
}
var vectorLayerStaedte = new LayerVector({
	zIndex: 3,
	source: vectorStaedte,
	style: styleStaedte
});
	
map.addLayer(vectorLayerStaedte);

////////////////////////////////////////////////////////////////////////////////////



}


	
	
	render() {   
	return (
		<div class="text-center " style={{position:'relative', marginTop: 60}}>
						
			<div class="" style={{position:'fixed', top:0, maxWidth: 996, zIndex:1000, width:'100%', color:'#ddd'}} >
				<div class=" navbar-brand menue-container" style={{backgroundColor:'#fff', borderBottom: '1px solid #393E41', padding:'0 0,', margin:'0 0',color:'#393E41' }} > 
					
					<div onClick={
					(e)=>{						
						this.props.app.loadUIHWMP(this.props.app.state.listHwmp, 'Alle HW-Meldepegel');	
	//this.props.app.showModalMenue()
					}}>
						<div class="header-text">Alle HW-Meldepegel</div>
						<div class="pl-2" style={{fontSize:12}} >mit Richtwertbezug nach HWMO ST</div>
					</div>

					<div class="my-auto" onClick={(e)=>{this.props.app.showModalMenue()}}>
					<img src="./icons/menue_points.png" class="" style={{width:40}}/>	 
					</div>
				</div>						
			</div>
			<div class="scrollRight" style={{position:'absolute', width:50, left:0, height: '100%', zIndex:997, opacity:0.0, backgroundColor:'#cdcdcd'}}></div>
			<div class="scrollRight" style={{position:'absolute',  width:50, right:0, height: '100%', zIndex:997, opacity:0.0, backgroundColor:'#cdcdcd'}}></div>
			

			<div id="map" class="map-view-content" />
				
			<div class="map-footer">
					<div  className=" buttonmap" onClick={()=>{this.setZoomUp()}}><img class="img-fluid img-shadow" src="./icons/zoom-in.png"/></div>
					<div className=" buttonmap" onClick={(e)=>{this.setZoomCenter()}}><img class="img-fluid" src="./icons/compress-white.png"/></div>
					<div className=" buttonmap" onClick={(e)=>{this.props.app.showModal()}}>
						 <img class="img-fluid" src="./icons/info-white.png" width="45"/>
						{(this.props.app.state.hwi.length > 0 || this.props.app.state.hww.length > 0)?
				<span class="info-point"><span class="visually-hidden"></span></span>
				:null}
			
						 
					 </div>		
					 
					 <div className=" buttonmap" onClick={(e)=>{this.setZoomDown()}}><img class="img-fluid" src="./icons/zoom-out.png"/></div>
			</div>			
			

		</div>
    );
  }
}


export default MapView;