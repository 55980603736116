import React, { Component } from 'react';

class Infoside extends React.Component {		
	componentDidMount(){}
	
    render() { 	
		return (
			<div class="text-dark text-center">	
				<div class=" navbar-brand header-container">
               
					<div class="my-auto " onClick={					
					(e)=>this.props.app.loadStartside()
					}>
					<img class="" src="./icons/arrow_left.png" style={{width:'35px'}}/>
					</div>

					
					<div class="header-text">App-Informationen</div>
					
					
					<div class="my-auto" onClick={(e)=>{this.props.app.showModalMenue()}}>
					<img src="./icons/menue_points.png" class="" style={{width:40}}/>	 
					</div>
      			</div>		
				{/* <div>
					<div id="" class="h5">Informationen</div>
				</div>
 */}
				<div class="shadow-box" >

					<div class="font-weight-bold">Datensatz generiert am: </div>					
					<div class="px-3">{this.props.createdAt}</div>
					<div class="font-weight-bold">App-Version:</div>
					<div class="px-3">{this.props.version}</div>
					<div class="font-weight-bold">Update:</div>
					<div class="px-3">{this.props.app.state.update}</div>
          		</div>
			</div>		
		);
  	}
}

export default Infoside;



